<template>
  <div class="newsDetail">
    <topNav ref="nav">
      <div class="sub-nav-bar flex-row align-center">
        <img class="sub-nav-icon" src="../assets/img-new/icon-home.png" />
        <router-link class="sub-nav-item active" to="/home">> 求职招聘 ></router-link>
        <router-link class="sub-nav-item active" to="/home"> 招聘考试 ></router-link>
        <span class="sub-nav-item">招聘公告</span>
      </div>
    </topNav>

    <div class="divBox">
      <div class="elrowBox">
        <div class="incontentRight" v-if="recruitment&&recruitment.title">
          <div class="detail-header flex-row">
            <div class="flex-1">
              <div>
                <p class="title">{{recruitment.title}}</p>
                <div></div>
              </div>
              <div class="info margin-top-20">报名时间：{{recruitment.applyStartTime}} ～ {{recruitment.applyEndTime}}</div>
              <div class="info margin-top-10">招聘单位：{{recruitment.recruitUnit}}</div>
            </div>
            <div v-if="recruitment&&recruitment.id&& (recruitment.status!=3 && recruitment.status!=4) &&recruitment.isEnter!=0">
              <el-button type="success" size="small" @click="handleApply">立即申请</el-button>
            </div>
            <div v-else>

            </div>
          </div>

          <div class="msgcontentTitle">
            <h1>{{recruitment.title}}</h1>
          </div>
          <div class="margin-top-30">
            <div v-html="recruitment.content"></div>
            <div v-if="fileList&&fileList.length" style="margin-top: 30px;">
              <p style="font-size: 16px; font-weight: 700;color: #F27E0B;margin-bottom: 15px;">附件下载：</p>
              <a target="_blank" v-for="item in fileList" :href="item.url">
                <p  style="background-color: #f8f7f3;padding: 3px 5px;">
                  <i class="el-icon-document" style="margin-right: 5px;"></i>{{item.name}}
                </p>
              </a>
            </div>
          </div>
          <div class="margin-tb70 text-right">
           <!-- <p class="info14 margin-bottom-20 ">{{recruitment.recruitUnit}}</p>
            <p class="info14 margin-tb10">{{recruitment.createTime}}</p> -->
          </div>
          <div class="flex justify_center padding-tb20" v-if="recruitment&&recruitment.id&& (recruitment.status!=3 && recruitment.status!=4) &&recruitment.isEnter!=0">
            <el-button type="info" icon="el-icon-edit" style="width: 200px;" @click="handleApply">立即申请</el-button>
          </div>
        </div>
      </div>
    </div>
    <footerBox></footerBox>


    <el-dialog title="选择简历" width="400px" :visible.sync="visible" :close-on-click-modal="false" center @close='closeVisible'>
      <el-radio-group v-model="resume">
        <el-radio :label="3"><span class="resumeTitle">简历1</span> <span class="resumeName">欢欢</span> <span class="resumeName">17371568956</span> </el-radio>
        <el-radio :label="6"><span class="resumeTitle">简历2</span> <span class="resumeName">欢欢</span> <span class="resumeName">17371568956</span></el-radio>
      </el-radio-group>
      <el-button style="width: 100%;margin: 30px 0 10px;letter-spacing: 3px;" type="success" @click="submitForm('ruleForm')">确认</el-button>
    </el-dialog>
<!--    <div v-if="process.env.NODE_ENV === 'test'">测试服务</div>-->
  </div>
</template>

<script>

  import{getRecruitmentInfo ,checkApply,addView} from '@/api/login.js';
  export default {
    name: 'home',
    components: {

    },
    data() {
      return {
        newList: [{
            name: '人力资源社会保障部办公厅关于进一步做好职称评审工作的通知',
            time: '2023-01-03'
          },
          {
            name: '收好这张图！享受个人养老金税收优惠政策三步走',
            time: '2023-01-03'
          },

        ],
        currentPage1: 5,
        currentPage2: 5,
        currentPage3: 5,
        currentPage4: 1,
        total: 5,
        pageSize: 10,
        titleOne: 1,
        visible: false,
        resume: null,

        recruitmentId:null,
        no:null,
        recruitment:{},

        fileList:[]
      }
    },
    computed: {
      loginOn() {
        return this.$store.state.haveLogin
      }
    },
    created() {
      console.log(window.location.href,'-------window.location.href')
      if(this.$route.query.id){
         this.recruitmentId =this.$route.query.id;
      }
      if(this.$route.query.no){
        this.no =this.$route.query.no;
      }
      this.getData();
    },
    methods: {
      getData(){
        getRecruitmentInfo(this.recruitmentId ,this.no).then(res=>{
          if(res.code==200){
            this.recruitment =res.data;
            let files=[]
            if(this.recruitment.files){

              try{
                files= JSON.parse(this.recruitment.files)
              }catch(e){
                let fileList= []
                fileList= this.recruitment.files.split(',');
                fileList.forEach(item=>{
                  let itemArr =item.split('/');
                  files.push({
                    url:item,
                    name:itemArr[itemArr.length-1]
                  })
                })
              }
              this.fileList =files;
            }
            let ssoToken=window.localStorage.getItem('ssoToken')||''
            addView({objectId:this.recruitment.id,ssoToken:ssoToken})
          }
        })
      },
      submitForm(){
        this.msgSuccess('已发送')
        this.closeVisible()
      },
      closeVisible(){
          this.resume=null;
          this.visible = false
      },
      handleApply() {
        if (this.$store.state.haveLogin) {
          checkApply(this.recruitment.id).then(res=>{
            if(res.code==200){
              let query={id:this.recruitment.id}
              if(this.recruitment.zpNo){
                query={no:this.recruitment.zpNo}
              }
              this.$router.push({
                path:'/applyPost',
                query:query
              })
            }else{
              this.$message.error(res.msg ?res.msg :'您不能申请该职位');
            }
          })
          return;
        } else {
          this.$refs.nav.loginTo();
          // this.msgError('请先登录')
        }
      },
      chose(e) {
        this.titleOne = e
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
    }
  }
</script>

<style lang="less" scoped>
  .recruitBtn {
    margin-bottom: 38px;

    li {
      width: 19%;
      padding-top: 9%;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }


  .incontentRight {
    margin-bottom: 48px;
    background: #FFFFFF;
    margin-top: 27px;
    padding: 42px 80px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .msgcontentTitle {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-top: 33px;
    h1 {
      font-size: 24px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .el-button--info{
    background: #FF6C2B;
    border: none;
  }
  .el-button--info:focus,
  .el-button--info.is-active,
  .el-button--info.is-disabled:active,
  .el-button--info:active {
    background: #FF6C2B;
    border: none;
  }

  .resumeTitle {
    font-size: 16px;
    color: #333;
  }

  .resumeName {
    font-size: 14px;
    color: #666;
    margin-left: 10px;
  }

  .el-radio {
    margin: 10px 0;
  }
</style>
<style>
  .newsDetail .msgcontentBox img {
    object-fit: contain;
    margin: 20px auto;
    display: inherit;
  }

  .newsDetail .msgcontentBox P,
  .newsDetail .msgcontentBox span {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #333333;
  }

  .detail-header{
    padding-bottom: 50px;
    border-bottom: 4px solid #2C3C6F;
    .title{
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      color: #333333;
    }
    .info{
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      color: #666666
    }
  }
</style>
