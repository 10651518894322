<template>
<el-upload
  class="avatar-uploader"
  accept="image/*"
  :action="uploadImgUrl"
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
  :before-upload="beforeAvatarUpload" name="avatarfile">
  <img v-if="imageUrl" :src="imageUrl" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>
</template>
<script>
  export default {
    data() {
      return {
        imageUrl: '',
        baseUrl: process.env.VUE_APP_BASE_API,
        uploadImgUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
        headers: {
          // Authorization: "Bearer " + getToken(),
        },
      };
    },
    props: {
      defaultImg: {
        type: String,
        default: null
      },
    },
    model: {
      prop: 'defaultImg',
      event: 'input'
    },
    watch: {
      defaultImg(newValue, oldValue) {
        this.imageUrl =  newValue
      }
    },
    created() {
      if(this.defaultImg){
        this.imageUrl =this.defaultImg
      }
    },
    methods: {
      handleAvatarSuccess(res, file) {
        if(res.code==200){
          this.imageUrl = res.imgUrl
          this.$emit('input',this.imageUrl)
        }else{
          this.$message.error(res.msg ?res.msg: '图片上传失败');
        }
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isLt2M;
      }
    }
  }
</script>
<style scoped lang="less">
  .avatar-uploader{
    width: 116px;
    height: 116px;
    line-height: 116px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.1);
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #d9d9d9;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 116px;
    height: 116px;
    line-height: 116px;
    text-align: center;
    border-radius: 50%;
  }
  .avatar {
    width:116px;
    height: 116px;
    border-radius: 50%;
    display: block;
  }
</style>
