<template>
  <div class="addresunmeBox">
     <!-- 基本信息开始 -->
    <div class="addresunmeTitle flex justify_between">
      <p>基本信息</p>
      <el-button type="text" icon="el-icon-edit" @click="basicMsgBtn" v-show="!basicMsg">编辑</el-button>
    </div>
    <div class="margin-bottom-20">
      <!-- 回显工作简历 -->
      <div class="basicMsgInfo flex border-bot pd-b30 justify_between" v-show="!basicMsg">
        <div>
          <h6>李某某</h6>
          <p class="info14 margin-tb10">
            <span class="pd-tb4" style="padding-left: 0;">女</span>|<span class="pd-tb4">22岁</span>|<span class="pd-tb4">应届</span>|<span class="pd-tb4">党员</span>
          </p>
          <div class="flex ">
               <p class="info14 marginr-30"><span class="color6">手机号：</span></p>
               <p class="info14 marginr-30"><span  class="color6">邮箱：</span></p>
               <p class="info14 marginr-30"><span  class="color6">身份证号：</span></p>
          </div>
        </div>
        <div style="margin-right: 10%;">
          <div class="haveheadPic" style="width: 98px;height: 126px;" v-show="headPicShow">
            <p class="haveheadPicEdit flex justify_center" @click="headPicShow=false"><i class="el-icon-edit"></i>修改</p>
            <img style="object-fit: cover;width: 100%;height: 100%;"  src="../../assets/muban.jpg" alt="">
          </div>
          <uplodeOne v-show="!headPicShow" v-model="info.headPic"></uplodeOne>
        </div>
      </div>
      <!-- 编辑工作简历 -->
      <el-collapse-transition>
        <div class="reformBox" v-show="basicMsg">
          <el-form :model="resumeForm" size="medium" :rules="rules" ref="resumeForm" label-width="100px"
            class="demo-ruleForm">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="真实姓名：" prop="name">
                  <el-input v-model="resumeForm.name" placeholder="请输入个人真实姓名"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证号：" prop="name">
                  <el-input v-model="resumeForm.card" placeholder="请输入身份证号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别：" prop="name">
                  <el-radio-group v-model="resumeForm.sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否应届：" prop="name">
                  <el-select style="width: 100%;" v-model="resumeForm.yj" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码：" prop="name">
                  <el-input v-model="resumeForm.mobile" placeholder="请填写手机号码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱：" prop="name">
                  <el-input v-model="resumeForm.email" placeholder="请填写邮箱"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="婚姻状况：" prop="name">
                  <el-input v-model="educationForm.hy" placeholder="请选择婚姻状况"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="政治面貌：" prop="name">
                  <el-input v-model="educationForm.zz" placeholder="中共党员（含预备党员）"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
              <div class="flex justify_center margin-top-20">
              <el-button type="success" @click="addresumeCommit('resumeForm')">保存</el-button>
               <el-button style="margin-left: 20px;" @click="basicMsg = false">取 消</el-button>
              </div>
          </el-form>

        </div>
      </el-collapse-transition>
    </div>
    <!-- 基本信息结束 -->


    <!-- 工作经历开始 -->
    <div class="addresunmeTitle flex justify_between">
      <p>工作经历</p>
      <el-button type="text" icon="el-icon-plus" @click="workBtn" v-show="!workMsg">添加内容</el-button>
    </div>
    <div class="margin-bottom-20">
      <ul class="border-bot pd-b30" v-if="workList.length>0&&!workMsg">
        <li class="flex justify_between align-start padding-tb20 " :class="index==3?'':'border-botd'" v-for="(item,index) in 4">
          <div style="width: 80%;" class="flexS0">
            <div class="flex margin-bottom-10">
              <p class="info14 margin-tb10">
                <span class="pd-tb4" style="padding-left: 0;">武汉XXXXXX有限责任公司</span>|<span class="pd-tb4">开发工程师</span>
              </p>
              <p class="info14 color6 marginl-30">2023.01~2023.01</p>
            </div>
              <div class="flex align-start">
                <p class="info14 color6 flexS0 " style="width: 70px;">离职原因：</p>
              <p class="info14 ">原职原因离职原因离职原因离职原因离职原因离职原因离职原因离职原因离职原因</p>
              </div>
          </div>
          <div class="greybtn">
              <el-button type="text" icon="el-icon-edit-outline" @click="workBtn">编辑</el-button>
              <el-button type="text" icon="el-icon-delete">删除</el-button>
          </div>
        </li>
      </ul>
      <!-- 添加工作经历 -->
      <div class="msgEmpty" v-if="workList.length<=0&&!workMsg">添加内容</div>
      <el-collapse-transition>
        <div class="reformBox" v-show="workMsg">
          <el-form :model="workForm" size="medium" :rules="rules" ref="workForm" label-width="100px"
            class="demo-ruleForm">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="公司名称：" prop="name">
                  <el-input v-model="workForm.name" placeholder="请输入公司名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="职位名称：" prop="name">
                  <el-input v-model="workForm.card" placeholder="请输入职位名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开始时间：" prop="name">
                  <el-date-picker style="width: 100%;" value-format="yyyy-MM-dd" v-model="workForm.date"
                    type="date" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="结束时间：" prop="name">
                  <div class="flex">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="workForm.date" type="date"
                      placeholder="选择日期">
                    </el-date-picker>
                    <el-checkbox style="margin-left: 10px;" v-model="checked">至今</el-checkbox>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="离职内容：" prop="name">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请输入内容"
                    v-model="workForm.zz">
                  </el-input>

                </el-form-item>
              </el-col>
            </el-row>
              <div class="flex justify_center margin-top-20">
              <el-button type="success" @click="addresumeCommit('workForm')">保存</el-button>
               <el-button style="margin-left: 20px;" @click="workMsg = false">取 消</el-button>
              </div>
          </el-form>

        </div>
      </el-collapse-transition>
    </div>
    <!-- 工作经历结束 -->

    <!-- 教育经历开始 -->
    <div class="addresunmeTitle flex justify_between">
      <p>教育经历</p>
      <el-button type="text" icon="el-icon-plus" @click="educationBtn" v-show="!educationMsg">添加内容</el-button>
    </div>
    <div class="margin-bottom-20">
      <ul class="border-bot pd-b30" v-if="educationList.length>0&&!educationMsg">
        <li class="flex justify_between  padding-tb20 " :class="index==3?'':'border-botd'" v-for="(item,index) in 4">
          <div style="width: 80%;" class="flexS0 flex ">
              <p class="info14 ">
                <span class="pd-tb4" style="padding-left: 0;">武汉工程大学</span>|<span class="pd-tb4">软件工程</span>|<span class="pd-tb4">本科</span>
              </p>
              <p class="info14 color6 marginl-30">2023.01~2023.01</p>
          </div>
          <div class="greybtn">
              <el-button type="text" icon="el-icon-edit-outline" @click="educationBtn">编辑</el-button>
              <el-button type="text" icon="el-icon-delete">删除</el-button>
          </div>
        </li>
      </ul>
    <div class="msgEmpty" v-if="educationList.length<=0&&!educationMsg">添加内容</div>
      <!-- 添加教育经历 -->
      <el-collapse-transition>
        <div class="reformBox" v-show="educationMsg">

          <el-form :model="educationForm" size="medium" :rules="rules" ref="educationForm" label-width="100px"
            class="demo-ruleForm">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="学校名称：" prop="name">
                  <el-input v-model="educationForm.name" placeholder="请输入学校名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所学专业：" prop="name">
                  <el-input v-model="educationForm.card" placeholder="请输入所学专业"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="学历：" prop="name">
                  <el-select style="width: 100%;" v-model="educationForm.yj" placeholder="请选择">
                    <el-option v-for="item in optionseducation" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="全日制：" prop="name">
                  <el-radio-group v-model="educationForm.sex">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="开始时间：" prop="name">
                  <el-date-picker style="width: 100%;" value-format="yyyy-MM-dd" v-model="educationForm.date"
                    type="date" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="结束时间：" prop="name">
                  <div class="flex">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="educationForm.date" type="date"
                      placeholder="选择日期">
                    </el-date-picker>
                    <el-checkbox style="margin-left: 10px;" v-model="checked">至今</el-checkbox>
                  </div>

                </el-form-item>
              </el-col>
               <el-col :span="24">
                 <el-form-item label="学信网截图" prop="name">
                    <FileUpload ></FileUpload>
                 </el-form-item>
               </el-col>

            </el-row>
              <div class="flex justify_center margin-top-20">
              <el-button type="success" @click="addresumeCommit('educationForm')">保存</el-button>
               <el-button style="margin-left: 20px;" @click="educationMsg = false">取 消</el-button>
              </div>
          </el-form>

        </div>
      </el-collapse-transition>
    </div>
      <!-- 教育经历结束 -->

      <!-- 证书开始 -->
      <div class="addresunmeTitle flex justify_between">
        <p>职(执)称与资格证件 <span style="font-size: 14px;color: #666;">（包含学历/学位证书）</span></p>
        <el-button type="text" icon="el-icon-plus" @click="certificateBtn" v-show="!certificateMsg">添加内容</el-button>
      </div>
      <div class="margin-bottom-20">
        <ul class="border-bot pd-b30" v-if="certificateList.length>0&&!certificateMsg">
          <li class="flex justify_between  padding-tb20 " :class="index==3?'':'border-botd'" v-for="(item,index) in 4">
            <div style="width: 80%;" class="flexS0 flex ">
                <p class="info14 ">国家一级工程师</p>
                <p class="info14 color6 marginl-30">BH268685653802</p>
                <p class="info14 color6 marginl-30">2023.01~2023.01</p>
            </div>
            <div class="greybtn">
                <el-button type="text" icon="el-icon-edit-outline" @click="certificateBtn">编辑</el-button>
                <el-button type="text" icon="el-icon-delete">删除</el-button>
            </div>
          </li>
        </ul>
        <div class="msgEmpty" v-if="certificateList.length<=0&&!certificateMsg">添加内容</div>
        <!-- 添加证书 -->
        <el-collapse-transition>
          <div class="reformBox" v-show="certificateMsg">
            <el-form :model="certificateForm" size="medium" :rules="rules" ref="certificateForm" label-width="100px"
              class="demo-ruleForm">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="证书名称：" prop="name">
                    <el-input v-model="certificateForm.name" placeholder="请输入证书名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="获得时间：" prop="name">
                    <el-date-picker style="width: 100%;" value-format="yyyy-MM-dd" v-model="certificateForm.date"
                      type="date" placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="证书编号：" prop="name">
                    <el-input v-model="certificateForm.card" placeholder="请输入证书编号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="证书扫描件" prop="name">
                     <FileUpload ></FileUpload>
                  </el-form-item>
                </el-col>
              </el-row>
                <div class="flex justify_center margin-top-20">
                <el-button type="success" @click="addresumeCommit('certificateForm')">保存</el-button>
                 <el-button style="margin-left: 20px;" @click="certificateMsg = false">取 消</el-button>
                </div>
            </el-form>

          </div>
        </el-collapse-transition>
      </div>
        <!-- 证书结束 -->
        <!-- 证书开始 -->
        <div class="addresunmeTitle flex justify_between">
          <p>附件简历 <span style="font-size: 14px;color: #666;">（包含学历/学位证书）</span></p>
          <!-- <el-button type="text" icon="el-icon-plus" @click="certificateBtn" v-show="!certificateMsg">添加内容</el-button> -->
        </div>
          <div>
             <FileUpload ></FileUpload>
          </div>
    <div>

    </div>
  </div>
</template>

<script>

  export default {
    name: 'home',
    components: {

    },
    props: {
      type: {
        type: String,
        default: null
      },
    },
    data() {
      return {
        resumeForm: {
          name: '',
          card: '',
          sex: 1,
          zz: '',
          hy: '',
          mobile: '',
          yj: '2'
        },
        rules: {
          name: [{
            required: true,
            message: '请输入简历名称',
            trigger: 'blur'
          }, ],

        },
        educationForm: {
          name: '',
          card: '',
          sex: 1,
          zz: '',
          hy: '',
          mobile: '',
          yj: '2',
          date: ''
        },
        workForm: {
          name: '',
          card: '',
          sex: 1,
          zz: '',
          hy: '',
          mobile: '',
          yj: '2',
          date: ''
        },
        certificateForm: {
          name: '',
          card: '',
          sex: 1,
          zz: '',
          hy: '',
          mobile: '',
          yj: '2',
          date: ''
        },
        options: [{
          value: '1',
          label: '是'
        }, {
          value: '2',
          label: '否'
        }],
        optionseducation: [{
          value: '1',
          label: '高中'
        }, {
          value: '2',
          label: '本科'
        }],
        checked: false,
        show: false,
        basicMsg: false,
        educationMsg: false,
        workMsg:false,
        certificateMsg:false,
        certificateList:[{}],
        educationList:[{}],
        workList:[{}],
        info:{
          headPic:'https://img1.baidu.com/it/u=1132380947,2947566169&fm=253&fmt=auto&app=138&f=JPEG?w=391&h=442'
        },
        headPicShow:false
      }
    },
    watch:{
      type(val){
        console.lof()
        if(val=='add'){
          this.basicMsg=true;
           this.educationMsg=true;
           this.workMsg=true;
           this.certificateMsg=true;
        }
      }
    },
    mounted() {
      if(this.type=='add'){
        this.basicMsg=true;
         this.educationMsg=true;
         this.workMsg=true;
         this.certificateMsg=true;
      }
        this.headPicShow=this.info.headPic?true:false
    },
    methods: {
      certificateBtn(){
        this.certificateMsg = true
      },
      workBtn(){
        this.workMsg = true
      },
      educationBtn() {
        this.educationMsg = true
      },
      basicMsgBtn() {
        this.basicMsg = true
      },
      addresumeCommit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.show = false;
            this.handleClose()
          } else {
            console.log('error submit!!');
            return false;
          }
        });

      },

    }
  }
</script>

<style lang="less" scoped>
  .addresunmeBox {
    padding: 14px 0;
  }

  .addresunmeTitle {
    margin-bottom: 18px;

    p {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      color: #333333;
      position: relative;

      &::before {
        content: '';
        width: 22px;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0px;
        background: #C8B484;
      }
    }
    /deep/ .el-button--text {
      color: #C8B484;
    }
  }

  .reformBox {
    padding: 20px 10px;
    background: #F7F8F9;
    color: #333333;

  }



  .msgEmpty{
      box-sizing: border-box;
      width: 100%;
      height: 98px;
      background: #F7F8F9;
      border: 1px dashed #C4CCD1;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height:98px;
      text-align: center;
      color: #333333;
  }


  .basicMsgInfo{

    h6{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 31px;
        color: #333333;
    }
  }

  /deep/ .el-upload--picture-card:hover{
  border-color: #C8B484!important;
  color: #C8B484!important;
  }
  .haveheadPic{
    position: relative;
    cursor: pointer;
    &:hover{
       .haveheadPicEdit{
         opacity: 1;
       }
    }
    .haveheadPicEdit{
      opacity: 0;
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.3);
      color: #fff;
      letter-spacing: 2px;
      transition: 0.5s;
    }
  }
</style>
