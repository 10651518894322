<template>
  <div class="logindialogBox">
    <el-dialog title="提示" :visible.sync="loginVisible" :close-on-click-modal="false" width="500px" center @close='closeLogin'>
      <div class="flex registerBox">
        <i class="el-icon-close reel-icon" @click="closeMe"></i>
        <div class=" loginInput flex justify_center">
          <div class="register-form">
             <h3>欢迎登录</h3>
            <ul class="loginType flex justify_center">
              <div class="loginTBorder" :style="{left: ltIndex==1?'87px':'228px'}"></div>
              <li :class="ltIndex==1?'activeLi':''" @click="choseLt(1)">
                账号登录
              </li>
              <li :class="ltIndex==2?'activeLi':''" @click="choseLt(2)">
                短信登录
              </li>
            </ul>
            <div>
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <!-- 账号密码登录 -->
                <template v-if="ltIndex==1">
                  <el-form-item prop="phone">
                    <el-input v-model="ruleForm.phone" placeholder="手机号" style="position: relative;"></el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input v-model="ruleForm.password" show-password placeholder="密码"></el-input>
                  </el-form-item>
                </template>
                <!-- 验证码登录 -->
                <template v-if="ltIndex==2">
                  <el-form-item prop="phone">
                    <el-input v-model="ruleForm.phone" placeholder="手机号" style="position: relative;"></el-input>
                  </el-form-item>
                  <el-form-item prop="code">
                    <div class="flex justify_between">
                       <el-input  v-model="ruleForm.code" placeholder="短信验证码"></el-input>
                      <span class="getCode" @click="handlegetCode">{{codeStr}}</span>
                    </div>
                  </el-form-item>
                </template>
                <div v-if="ltIndex==1" class="gotoLogin flex justify_between" style="text-align: right;margin-bottom: 20px;width: 100%;">
                   <!-- <el-checkbox v-model="remeber">记住我</el-checkbox> -->
                  <span class="cursor-pointer hover-color" style="color: #C8B484;" @click="forgetPass">忘记密码?</span>
                  </div>

                  <!-- <div class="gotoLogin flex justify_between" style="text-align: right;margin-bottom: 20px;width: 100%;">
                     <el-checkbox v-model="remeber">已阅读并同意 <span class="cursor-pointer" style="color: #C8B484;">《用户服务协议》</span>和<span class="cursor-pointer" style="color: #C8B484;">《隐私政策》</span></el-checkbox>
                  </div> -->
                  <el-form-item>
                  <el-button type="success" @click="submitForm('ruleForm')" class="registerBtn">登录</el-button>
                </el-form-item>

              </el-form>
            </div>
            <div class="gotoLogin margin-top-30 flex" style="text-align: center;width: 100% ;justify-content: center;">
              <!-- <P class="cursor-pointer " >
                <a target="_blank" href="https://whzsh.com/zpadmin" style="color:#C8B484">企业登录</a>
              </P> -->
              <p>还没有账号? <span style="color: #F27E0B;" class="hover-color"
                @click="gotoRegister">立即注册</span> </p>
              </div>
          </div>
        </div>
      </div>

    </el-dialog>

    <el-dialog width="500px" :visible.sync="forgetVisible" :close-on-click-modal="false" center @close='closeforget'>
      <div class=" loginInput flex justify_center">
        <i class="el-icon-close reel-icon" @click="closeMe2"></i>
        <div  class="register-form">
          <h3>忘记密码</h3>
          <div>
            <el-form :model="forgotForm" :rules="forgotRules" ref="forgotForm" class="demo-ruleForm">
              <el-form-item prop="phone">
                <el-input v-model="forgotForm.phone" placeholder="手机号"></el-input>

              </el-form-item>
              <el-form-item prop="code">
                <el-input v-model="forgotForm.code " placeholder="验证码" style="position: relative;"></el-input>
                <span class="getCode" @click="getForgetCode">{{forgotVcode.text}}</span>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="forgotForm.password" show-password placeholder="新密码"></el-input>
              </el-form-item>
              <el-form-item prop="repassword">
                <el-input v-model="forgotForm.repassword" show-password placeholder="确认新密码"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="success" @click="submitforgotForm('forgotForm')" class="registerBtn">重置密码</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="gotoLogin margin-top-30" style="text-align: center;width: 100%;">返回 <span class="hover-color"
              @click="gotoLogin">登录</span> </div>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  import {
    getCode,
    login,
    yzmlogin,
    sendVcode,
    forgetPassword
  } from "@/api/login.js"
  import {
    setToken
  } from '@/utils/auth'
  var timer = null
  export default {
    name: 'home',
    components: {},
    data() {
      var phone = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入手机号'));
        } else {
          var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
          if (!reg_tel.test(value)) {
            callback(new Error('请输入正确的手机号'));
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.forgotForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        Sindex: 0,
        searchone: 1,
        loginVisible: false,
        forgetVisible: false,
        ruleForm: {
          phone: null,
          password: '',
          code: ''
        },
        rules: {
          phone: [{
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }],
          code: [{
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }],
        },
        ltIndex: 1,
        checked: false,
        codeStr: '获取验证码',
        isSend: true,
        timeNum: 60,

        forgotForm: {
          phone: '',
          code: '',
          password: '',
          repassword: '',
        },
        forgotRules:{
          phone: [{
            validator: phone,
            trigger: 'blur'
          }],
          code: [{
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }],
          password: [{
              required: true,
              message: '请输入新密码',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 12,
              message: '长度在 3 到 12 个字符',
              trigger: 'blur'
            }
          ],
          repassword: [{
            validator: validatePass2,
            trigger: 'blur'
          }],
        },
        forgotVcode:{
          text: '获取验证码',
          timeNum: 0,
          timer:null
        },
        loginType:false,
        remeber:false
      }
    },
    methods: {

      getForgetCode(){
        if(this.forgotVcode.timeNum>0){
          return;
        }
        if (this.forgotForm.phone) {
          var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
          if (reg_tel.test(this.forgotForm.phone)) {

            let formData={
              phone:this.forgotForm.phone,
              type:1
            }
            sendVcode(formData).then(res => {
              if(res.code=200){
                this.createForgetTimer()
                this.$message.success('验证码发送成功');
              }else{
                this.$message.error(res.msg ?res.msg: '验证码发送失败');
              }

            })
          } else {
            this.$message.error('请输入正确的手机号');
          }

        } else {
          this.$message.error('请输入手机号');
        }
      },
      createForgetTimer(){
        this.forgotVcode.timeNum=60;
        this.forgotVcode.timer = setInterval(()=> {
            if (this.forgotVcode.timeNum<1) {
              clearInterval( this.forgotVcode.timer);
              this.forgotVcode.timer =null;
              this.forgotVcode.timeNum =0;
              this.forgotVcode.text ='获取验证码'
            } else {
              this.forgotVcode.timeNum -= 1
              this.forgotVcode.text = this.forgotVcode.timeNum + "秒后重试"
            }
          }, 1000)
      },
      handlegetCode() {
        if (this.isSend) {
          if (this.ruleForm.phone) {
            var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
            if (reg_tel.test(this.ruleForm.phone)) {

              let formData={
                phone:this.ruleForm.phone,
                type:3
              }
              sendVcode(formData).then(res => {
                if(res.code=200){
                  this.timerOn()
                  this.$message.success('验证码发送成功');
                }else{
                  this.$message.error(res.msg ?res.msg: '验证码发送失败');
                }

              })
            } else {
              this.$message.error('请输入正确的手机号');
            }

          } else {
            this.$message.error('请输入手机号');
          }
        }
      },
      timerOn() {
        let _this = this
        _this.isSend = false
        timer = setInterval(()=> {
            console.log(_this.codeStr)
            if (_this.timeNum == 0) {
               _this.closeTimeer()
            } else {
              _this.timeNum -= 1
              _this.codeStr = _this.timeNum + "秒后重试"
            }
          }, 1000)
      },
      closeTimeer(){
        this.timeNum = 60;
        this.codeStr = "获取验证码";
        this.isSend = true;
         clearInterval(timer)
      },
      handleClose() {

      },
      forgetPass() {
         this.closeTimeer()
        this.closeLogin()
        this.forgetVisible = true;

      },
      closeMe2() {
        this.forgetVisible = false;
      },

      choseLt(e) {
        this.closeTimeer()
        this.ltIndex = e
        this.$refs.ruleForm.clearValidate();
      },
      gotoLogin() {
        this.forgetVisible = false;
        this.loginVisible = true;
      },
      gotoRegister() {
         this.closeTimeer()
        this.closeLogin()
        this.$emit('register')
      },
      closeforget(){
        this.forgotForm={
          phone: '',
          password: '',
          repassword: '',
          code: ''
        }
        this.$refs.forgotForm.resetFields()
        this.forgetVisible = false;
      },
      closeLogin(){
        this.ruleForm={
          phone: null,
          password: '',
          passid: ''
        }
         this.$refs.ruleForm.resetFields()
         this.loginVisible = false;
      },


      submitforgotForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            forgetPassword(this.forgotForm).then(res=>{
              if(res.code==200){
                this.$message.success("密码重置成功");
                this.closeforget();
                this.loginVisible =true;
              }else{
                this.$message.error("密码重置失败");
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      submitForm(formName) {

        let that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {

            if (that.ltIndex == 1) {
              // 密碼登錄
              let formData={
                phone: that.ruleForm.phone,
                password: that.ruleForm.password,
                type:1
              }
              login(formData).then(res => {
                if (res.code == 200) {
                  that.$message.success("登录成功");
                  setToken(res.token)
                  this.$emit("loginOn")
                  that.closeLogin()
                } else {
                  that.$message.error(res.msg);
                }
              })
            } else {
              // 验证码登录
              let formData={
                phone: that.ruleForm.phone,
                code: that.ruleForm.code,
                type:2
              }
              yzmlogin(formData).then(res => {
                if (res.code == 200) {
                  that.$message.success("登录成功");
                  setToken(res.token)
                  this.$emit("loginOn")
                  that.closeLogin()
                } else {
                  that.$message.error(res.msg);
                }
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      closeMe() {
        this.loginVisible = false
      },
      open() {
        this.loginVisible = true
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #606266;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #C8B484;
      border-color: #C8B484;
  }
  .registerBox {
    position: relative;
  }

  .reel-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #B8BDCD;
    font-size: 24px;
  }

  .logindialogBox /deep/ .el-dialog__header {
    display: none !important;
  }

  .logindialogBox /deep/ .el-dialog__body {
    padding: 0 !important;
  }

  // .registerBox .registerImg {
  //   width: 606px;
  //   height: 618px;

  // }

  // .loginInput {
  //   width: 400px;
  //   height: 618px;
  //   background-color: #fff;
  //   position: relative;
  // }

  .getCode {
    position: absolute;
    right: 0;
    top:0;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    width: 34%;
    border-radius: 4px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    /* identical to box height */
    color: #AD9C72;
    &::before{
      content: "";
      position: absolute;
      top: 13px;
      left: 0px;
      width:1px;
      height: 16px;
      background-color: #AD9C72;
    }
  }
  .loginInput{
    width: 100%;
    padding: 56px 0 36px;
  }
  .loginInput h3 {
    text-align: center;
   font-size: 32px;
   color: #333;
    margin-bottom: 30px;
    img{
      height: 100%;
    }
  }



  .loginInput .el-form {
    width: 100%;
  }

  .registerBtn {
    width: 100%;
    height: 46px;
    background: #C8B484;
    // border-radius: 61px 61px 61px 61px !important;;
    border-color: #C8B484;
    font-size: 16px;
    &:hover {
      background: #C8B484;
      // border-radius: 61px 61px 61px 61px !important;;
      border-color: #C8B484;
    }
  }

  .gotoLogin {
    width: 47px;
    height: 18px;
    font-size: 14px;
    font-family: OPPOSans-Regular, OPPOSans;
    font-weight: 400;
    color: #8E8C8C;
    line-height: 16px;

    span {
      cursor: pointer;
      color: #000;
    }
  }


  .register-form{
    width: 80%;
    h3{
      text-align: center;
      height: 34px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 34px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width:576px){
    .logindialogBox{
      .el-dialog {
        width: 90% !important;
        max-width: 400px;
      }
      .register-form{
        width: 80%;
      }
      .reel-icon{
        right: 10px;
        top: 10px;
        font-size: 20px;
        color: #8E8C8C;
      }
      .loginInput{
        width: 100%;
        height: 480px;
      }
    }
  }



 .loginType {
   // border-bottom: 1px solid #E2E2E2;
   position: relative;
   margin-bottom: 30px;

   .loginTBorder {
     position: absolute;
     bottom: 0;
     left: 87px;
     width: 90px;
     height: 4px;
     background-color: #C8B484;
     transition: 0.5s;
     border-radius: 20px;
   }

   li {
     text-align: center;
     // width: 50%;
     height:66px;
     font-size: 20px;
     font-family: OPPOSans-Regular, OPPOSans;
     font-weight: 600;
     color: #666666;
     line-height: 66px;
     cursor: pointer;
     margin: 0 30px;
   }

   .activeLi {
     position: relative;
     color: #C8B484;

   }
 }
</style>
