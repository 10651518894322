<template>
  <div class="component-upload-image">


    <el-upload
      :action="uploadImgUrl"
      :list-type="'picture-card'"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      name="avatarfile "
      :on-remove="handleRemove"
      :show-file-list="false"
      :headers="headers"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview">
      <i class="el-icon-plus"></i>
      <span>上传证件照</span>
    </el-upload>

    <!-- 上传提示 -->
    <div class="el-upload__tip" slot="tip" v-if="showTip">
      <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
      <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template>
      的文件
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      title="预览"
      width="800"
      append-to-body
    >
      <img
        :src="dialogImageUrl"
        style="display: block; max-width: 100%; margin: 0 auto"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";

export default {
  props: {
    value: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 1,
    },
    // 大小限制(MB)
    fileSize: {
       type: Number,
      default: 5,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/member/upload", // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      fileList: []
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          // 首先将值转为数组
          try{
            if(this.limit>1){
              const list = Array.isArray(val) ? val : JSON.parse(val);
              // 然后将数组转为对象数组
              this.fileList = list.map(item => {
                if (typeof item === "string") {
                  item = { name: item, url: item };
                }
                return item;
              });
            }else{
              this.fileList = Array.isArray(val) ? val : [{ name: val, url: val }];
            }

          }catch(e){
            this.fileList = [];
          }
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
    // 删除图片
    handleRemove(file, fileList) {
      const findex = this.fileList.map(f => f.name).indexOf(file.name);
      this.fileList.splice(findex, 1);
      this.$emit("input", this.fileList);
    },
    // 上传成功回调
    handleUploadSuccess(res) {
      if (res.code == 200) {
        // this.fileList.push({ name: res.data.fileName, url: res.data.url });
        this.fileList.push({ name: res.imgUrl, url: res.imgUrl });
        // this.$emit("input", this.listToString(this.fileList));
        this.$emit('input' ,this.fileList);
        this.loading.close();
      } else {
        this.$message.error(res.msg);
        this.loading.close();
      }
    },
    // 上传前loading加载
    handleBeforeUpload(file) {
      let isImg = false;
      if (this.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        this.$message.error(
          `文件格式不正确, 请上传${this.fileType.join("/")}图片格式文件!`
        );
        return false;
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      this.headers.Authorization ="Bearer " + getToken()
      this.loading = this.$loading({
        lock: true,
        text: "上传中",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传失败
    handleUploadError(res) {
      this.$message({
        type: "error",
        message: "上传失败",
      });
      this.loading.close();
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        strs += list[i].url + separator;
      }
      return strs != "" ? strs.substr(0, strs.length - 1) : "";
    },
  },
};
</script>
<style scoped lang="less">
.component-upload-image{
  width: 98px;height: 126px;
}
.hide{
  width: 98px;height: 126px;
  /deep/ .el-upload--picture-card {
    // display: none;
  }
  // 去掉动画效果
  /deep/ .el-list-enter-active,
  /deep/ .el-list-leave-active {
    transition: all 0s;
  }

  /deep/ .el-list-enter,
  .el-list-leave-active {
    opacity: 0;
    transform: translateY(0);
  }

}
/deep/ .el-upload--picture-card{
 width: 98px!important;
 height: 126px!important;
   line-height: 26px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item{
  width: 98px!important;
  height: 126px!important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  object-fit: cover;
}
@media screen and (max-width:576px){
  /deep/ .el-upload--picture-card{
    max-width: 100% !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item{
   max-width: 100% !important;
  }

}
</style>
