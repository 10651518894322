<template>
  <div class="flex flex_column justify_center" >
   <img :style="{width: width}"  class="emptyImg" src="../assets/empty.png" alt="">
   <p v-if="showText" style="padding:10px 0;color: #999;text-align: center;">{{text}}</p>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: "暂无数据",
      },
      showText:true,
      width:{
        type: String,
        default: "208px",
      },
    },
    data() {
      return {}
    }
  }
</script>

<style>
  .emptyImg{
    /* width: 208px; */
  }
    @media screen and (max-width:576px){
      .emptyImg{
        width: 50%;
      }

    }
</style>
