<template>
  <div>
    <topNav :subNav="false">
    </topNav>
    <div class="top-banner" style="height:500px;">
      <img :src="banner" alt="" style="object-fit: cover;">
    </div>
    <div class="divBox">

      <div class="elrowBox" style="min-height: 60vh;">
        <div class="recruit-list">
          <div class="recruit-item flex-row align-center" v-for="(item,index) in newList" :key="index">
            <div class="flex-1">
              <div class="title curser-pointer"  @click="gotoDetail(item)">
                <div v-if="item.status==3 || item.status==4 ||item.isEnter==0" class="icon-end">已结束</div>
                <div v-else class="icon-active">进行中</div>
                <div class="line-1">{{item.title}}</div>
              </div>
              <div class="info margin-top-10">
                <p>报名时间：{{item.applyStartTime}} ～ {{item.applyEndTime}}</p>
                <p class="margin-top-20 flex-row align-center"><img class="icon-unit" src="../assets/img-new/icon-unit.png">{{item.recruitUnit}}</p>
              </div>

            </div>

            <div v-if="item.status==3 || item.status==4 ||item.isEnter==0">
              <div class="text-center margin-bottom-20">报名截止</div>
              <el-button size="small" class="stop" @click="gotoDetail(item)">查看详情 ></el-button>
            </div>
            <div v-else>
              <div class="text-center margin-bottom-20 text-primary">进行中</div>
              <el-button size="small" type="success" @click="gotoDetail(item)">查看详情 ></el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <footerBox></footerBox>
  </div>
</template>

<script>
  import{getRecruitmentList ,getRecruitmentTopic} from '@/api/login.js';
  export default {
    name: 'recruit',
    components: {

    },
    data() {
      return {
        newList:[],
        topicId:null,
        banner:null,
      }
    },
    watch: {
      $route:{
        handler(to,form){
          this.getData();
        },
        deep:true
      }
    },
    created() {
      this.getData();
    },
    methods:{
      getData(){
        if(this.$route.query.topicId){
          this.topicId = this.$route.query.topicId;
          this.getTopic();
        }else{
          this.topicId = null;
         this.banner='/static/home-banner.png';
        }

        this.getList();
      },
      getTopic(){
        getRecruitmentTopic(this.topicId).then(res=>{
          if(res.code==200){
            this.banner= res.data.banner ?res.data.banner: '/static/home-banner.png';
          }
        })
      },
      getList(){
        let query={}
        if(this.topicId){
          query.topicId= this.topicId
        }
        console.log(query)
        getRecruitmentList(query).then(res=>{
          if(res.rows){
            this.newList = res.rows;
          }
        })
      },
      gotoDetail(e){
        let query={
          id:e.id
        }
        if(e.zpNo){
          query={
            no:e.zpNo
          }
        }
        this.$router.push({path:'/detail',query})
      },
      chose(e){
        this.titleOne=e
      },
      handleSizeChange(val) {
              console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
              console.log(`当前页: ${val}`);
            }
    }
  }
</script>

<style lang="less" scoped>
.recruitBtn{
  margin-bottom: 38px ;
  li{
    width: 19%;
    padding-top: 9%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    img{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


      .incontentRight{
        margin-bottom: 48px;
        background: #FFFFFF;

        .incRightTitle{
            height: 60px;
            padding: 0 8px;
            li{
              cursor: pointer;
              line-height: 48px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 48px;
              color: #333333;
              padding: 0 16px;
            }
            .activeLi{
              color: #AD9C72;

            }
        }

      }
      .incRightCon{
          padding: 16px;
      }
       .incRightCon .incRightConHot{
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #FDF7ED;
        border-radius: 10px;
        p{
          &:first-child{
            width: 52px;
            flex-shrink: 0;
            text-align: center;
            // padding: 0 10px;
              height: 29px;
              background: linear-gradient(90deg, #F6A14B -1.91%, #F27E0B 100%);
              border-radius: 4px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 29px;
              color: #FFFFFF;
              margin: 0 10px;
          }
          &:last-child{
            flex: 1;
            flex-shrink: 0;
            width: calc(100% - 60px);
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 29px;
              color: #333333;
          }
        }
      }
      .incRightConUl{

        li{

          line-height: 48px;
          height: 48px;
          i{
            font-size: 26px;
            color:#AD9C72;
          }
          div{
            overflow: hidden;
            // max-width: 80%;
          }
          .content{
             max-width: 85%;
            color: #333333;
          }
          p{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

          }
          .time{
            width:100px ;
            text-align: right;
             color: #999999;
          }
          .status{
            width: 72px;
            height: 24px;
            background: #FDF7ED;
            border-radius: 4px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #FF5202;
            text-align: center;
          }
        }

      }
      
      .recruit-list{
        margin-top: 33px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        .recruit-item{
          padding: 25px 20px;
          border-top: 1px solid #F2F2F2;
          margin: 0 20px;
          &:first-child{
            border-top:none;
          }
      
          .title{
            font-size: 18px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon-end{
              background-image: url(../assets/img-new/icon-end.png);
              background-size: 100% 100%;
              width: 48px;
              height: 28px;
              display: flex;
              align-items: center;
              font-size: 12px;
              font-family: PingFang SC-Medium, PingFang SC;
              color: #FFFFFF;
              padding-left: 8px;
              margin-right: 10px;
            }
            .icon-active{
              background-image: url(../assets/img-new/icon-active.png);
              background-size: 100% 100%;
              width: 48px;
              height: 28px;
              display: flex;
              align-items: center;
              font-size: 12px;
              font-family: PingFang SC-Medium, PingFang SC;
              color: #FFFFFF;
              padding-left: 8px;
              margin-right: 10px;
            }
          }
          .info{
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            .icon-unit{
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
          }
        }
      }
</style>
