<template>
  <div>
    <topNav></topNav>
    <div class="mine-banner">
      <img src="../assets/image/mineBanner.png" alt="">
    </div>
    <div class="divBox">
      <div class="elrowBox" style="margin-top: -123px;">
        <el-row type="flex" justify="center">
          <el-col :lg="24" :xl="24" :md="22" :sm='23' :xs='23'>
            <div class="flex justify_between align-start">
              <mineMenu></mineMenu>
              <div class="miContent flex-1">
                <div class="miCtitle">系统消息</div>
                <div class="miCbody">
                 <!-- <div class="flex justify_between">
                    <div class="unread">未读 <span>2</span> 封</div>
                    <div class="flex">
                      <div class="lookStatus flex">查看状态
                        <el-select v-model="value" placeholder="请选择">
                          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="miCbodyBtn">全部已读</div>
                    </div>
                  </div> -->
                  <div class="msgTableBox">
                    <el-table :data="tableData" style="width: 100%">
                      <el-table-column prop="con" label="消息内容" min-width="550">
                      </el-table-column>
                      <el-table-column prop="time" label="发送时间" width="160">
                      </el-table-column>
                    <!--  <el-table-column label="查看状态" min-width="100" align="center">
                        <template slot-scope="scope">
                          <span v-if="scope.row.red==1">已读</span>
                          <span v-else style="color:#F40707 ;">未读</span>
                        </template>
                      </el-table-column> -->
                    </el-table>
                  </div>
                  <div class="flex justify_end">
                  <pagination  :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
                   </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <footerBox></footerBox>
  </div>
</template>

<script>
  export default {
    name: 'home',
    components: {

    },
    data() {
      return {
        queryParams:{
          pageNum:1,
          pageSize:10,
        },
        total: 5,
        titleOne: 1,
        options: [{
          value: '选项1',
          label: '不限'
        }, {
          value: '选项2',
          label: '已读'
        }, {
          value: '选项3',
          label: '未读'
        }],
        value: '',
        tableData: [{
          con: '系统检测到您的简历信息完善度比较低,完善度较高的简历被企业查看到的几率更多，建议您尽快完善简历信息。',
          time:'2023-01-09 14:38:30',
          red:1,
        },
        {
          con: '系统检测到您的简历信息完善度比较低,完善度较高的简历被企业查看到的几率更多，建议您尽快完善简历信息。',
          time:'2023-01-09 14:38:30',
          red:2,
        }]
      }
    },
    methods: {
      getList(){

      },
      chose(e) {
        this.titleOne = e
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
    }
  }
</script>

<style lang="less" scoped>
  .recruitBtn {
    margin-bottom: 38px;

    li {
      width: 19%;
      padding-top: 9%;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }


  .incontentRight {
    margin-bottom: 48px;
    background: #FFFFFF;
    margin-top: 27px;

    .incRightTitle {
      height: 48px;
      padding: 0 8px;

      li {
        line-height: 48px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 48px;
        color: #333333;
        padding: 0 16px;
      }

      .activeLi {
        color: #AD9C72;

      }
    }

  }

  .incRightCon {
    padding: 16px;
  }

  .incRightCon .incRightConHot {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #FDF7ED;
    border-radius: 10px;

    p {
      &:first-child {
        width: 52px;
        flex-shrink: 0;
        text-align: center;
        // padding: 0 10px;
        height: 29px;
        background: linear-gradient(90deg, #F6A14B -1.91%, #F27E0B 100%);
        border-radius: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #FFFFFF;
        margin: 0 10px;
      }

      &:last-child {
        flex: 1;
        flex-shrink: 0;
        width: calc(100% - 60px);
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: #333333;
      }
    }
  }

  .incRightConUl {
    min-height: 480px;

    li {

      line-height: 48px;
      height: 48px;

      i {
        font-size: 26px;
        color: #AD9C72;
      }

      div {
        overflow: hidden;
        // max-width: 80%;
      }

      .content {
        width: 85%;
        color: #333333;
      }

      p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

      }

      .time {
        width: 100px;
        text-align: right;
        color: #999999;
      }

      .status {
        width: 72px;
        height: 24px;
        background: #FDF7ED;
        border-radius: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FF5202;
        text-align: center;
      }
    }

  }

  .miContent {
    background: #FFFFFF;
  }

  .miCtitle {
    padding: 20px 37px;
    position: relative;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    border-bottom: 1px solid #DFDFDF;

    &::before {
      content: '';
      width: 4px;
      height: 20px;
      position: absolute;
      top: 20px;
      left: 25px;
      background: #C8B484;
    }
  }

  .miCbody {
    padding: 30px 40px 0;
    min-height: 529px;
  }

  .miCbodyBtn {
    cursor: pointer;
    width: 88px;
    height: 32px;
    background: #FDF7ED;
    border-radius: 2px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #AD9C72;
    text-align: center;
  }

  .unread {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    span {
      color: #FF0000;
    }
  }

  .lookStatus {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    /deep/ .el-input__inner {
      border: 0 !important;
      background: #F7F8F9;
      height: 32px;
      line-height: 32px;
    }

    /deep/.el-select {
      width: 100px !important;
      margin: 0 10px !important;
    }

    /deep/ .el-input__icon {
      line-height: 32px;
    }
  }

  .msgTableBox {
    min-height: 400px;
    // padding: 30px 0;
    /deep/ .el-table__header-wrapper th{
        background: #F7F8F9;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
         border: 0!important;
    }
    /deep/ tr:last-child td{
      border: 0!important;
    }
     /deep/ .el-table--border::after, .el-table--group::after, .el-table::before{
      display: none!important;
    }
     /deep/ .el-table .el-table__cell{
      padding: 20px 0;
    }
    // /deep/  .el-table td.el-table__cell div{
    //   color: #333;
    // }

  }
</style>
