<template>
  <div class="mileftMenu">
    <div class="miMsg flex">
      <div class="miMsgHead"><img src="../assets/img-new/avatar.png" alt=""></div>
      <div class="miMsgInfo">
        <h5>Hi,欢迎您</h5>
        <h6>{{userInfo|showName}}</h6>
      </div>
    </div>
    <!-- <p class="miMsgTime">上次登录时间：当前第一次登录</p> -->
    <ul class="mimenuBox">
      <!-- <router-link to="/mineInfo">
        <li class="flex">
          <img src="../assets/image/micon1.png" alt="">
          <p>账号信息</p>
        </li>
      </router-link> -->
 <!--     <router-link to="/resume">
        <li class="flex">
          <img src="../assets/image/micon2.png" alt="">
          <p>我的简历</p>
        </li>
      </router-link> -->
      <router-link to="/apply">
        <li class="flex">
          <img src="../assets/img-new/mine-apply.png" alt="">
          <p>我的申请</p>
        </li>
      </router-link>
      <!-- <router-link to="/message">
        <li class="flex">
          <img src="../assets/image/micon4.png" alt="">
          <p>系统消息 <span class="tipsNum">9</span> </p>
        </li>
      </router-link> -->
    </ul>
    <!-- <div class="mineQRcode flexC ">
      <img src="../assets/mineqr.png" alt="">
      <p>关注官方公众号</p>
      <p>获取最新岗位消息</p>
    </div> -->
  </div>
</template>

<script>
  import {
    getToken,removeToken
  } from '@/utils/auth'
  import {
    getInfo
  } from "@/api/login.js"

  export default {
    props: {
      defaultValue: {
        type: [String, Number],
        default: null
      },
    },
    model: {
      prop: 'defaultValue',
      event: 'input'
    },
    data() {
      return {
        dynamicTags: [],
        inputVisible: false,
        inputValue: '',
        userInfo:{}
      };
    },
    mounted() {
      this.loginOnto()
    },
    methods: {
      loginOnto() {
        if (getToken()) {
          this.loginOn=true
          getInfo().then(res => {
              if(res.code==200){
                this.userInfo =res.data;
                this.$store.state.haveLogin =true;
              }else{
                this.$store.state.haveLogin =false;
              }
          }).catch(e=>{
            this.$store.state.haveLogin =false;
          })
        } else {
          this.$store.state.haveLogin =false;
        }

      },
    }
  }
</script>


<style lang="less" scoped>
  .mileftMenu {
    width: 248px;
    flex-shrink: 0;
    margin-right: 10px;
    background: linear-gradient(180deg, #FFFFFF 57.26%, rgba(255, 255, 255, 0) 100%);
    min-height: 654px;
  }

  .miMsg {
    padding: 15px 15px 10px;

    .miMsgHead {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 14px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover
      }
    }

    .miMsgInfo {

      h5 {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #333333;

      }

      h6 {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: #333333;

        span {
          font-size: 16px;
          color: #AD9C72;

        }
      }
    }
  }

  .miMsgTime {
    padding: 0 15px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin: 0px 0 22px;
  }

  .mimenuBox {
    margin-top: 25px;
    a {
      display: inline-block;
      width: 100%;
      height: 100%;

    }

    li {
      // width: 100%;
      height: 48px;
      padding: 0 19px;

      img {
        width: 17px;
        height: 17px;
        margin-right: 12px;
      }

      p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 48px;
        color: #333333;

      }

    }
  }

  .router-link-active {
    background: #F4F7FC;
    position: relative;

    &::before {
      content: '';
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #2C3C6F;
    }
  }

  .tipsNum {
    display: inline-block;
    min-width: 18px;
    height: 18px;
    background-color: #F40707;
    color: #FFFFFF;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0 6px;
    box-sizing: border-box;
    color: #FFFFFF;
    border-radius: 30px;

  }

  .mineQRcode{
    margin-top: 120px;
    img{
      width: 106px;
      height: 106px;
    }
    p{
      text-align: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #333333;
      margin-top: 10px;

    }
  }
</style>
