<template>
  <div>
    <topNav></topNav>
    <div class="mine-banner">
      <img src="../../assets/image/mineBanner.png" alt="">
    </div>
    <div class="divBox">
      <div class="elrowBox" style="margin-top: -123px;">
        <el-row type="flex" justify="center">
          <el-col :lg="24" :xl="24" :md="22" :sm='23' :xs='23'>
            <div class="flex justify_between align-start">
              <mineMenu></mineMenu>
              <div class="miContent flex-1 ">
                <div class="miCtitle">我的简历 <p class="miCbodyBtn" v-if="show" @click="addresume"> <i
                      class="el-icon-plus"></i> 新增简历</p>
                  <p class=" mibackBtn miCbodyBtn" v-else @click="show=true"> <i class="el-icon-back"></i> 返回</p>
                </div>
                <div class="miCbody">
                  <!-- 列表 -->
                  <div class="msgTableBox" v-if="show">
                    <el-table v-if='tableData.length>0' :data="tableData" :show-header="false" style="width: 100%">
                      <el-table-column prop="con" min-width="550">
                        <template slot-scope="scope">
                          <div class="cursor-pointer">
                            <div class="flex resumName margin-bottom-10">
                              <p>简历名称</p>
                              <span>创建时间：2023-12-12</span>
                            </div>
                            <div class="flex resuMore margin-bottom-10">
                              <span>女</span>|<span>12岁</span>|<span>应届</span>|<span>党员</span>
                            </div>
                            <div class="flex resuMore">
                              <p><span style="color: #666;">手机号：</span></p>
                              <p style="margin-left: 35px;"><span style="color: #666;">邮箱：</span>@qq.com</p>
                            </div>
                          </div>

                        </template>
                      </el-table-column>
                      <el-table-column width="160" align="center">
                        <template slot-scope="scope">
                          <el-button type="text" icon="el-icon-view" @click="handleUpdate(scope.row)">查看</el-button>
                          <el-button type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>

                    <div v-else class="emptyDiv">
                      <empty></empty>
                      <p class="">暂无简历 <span @click="addresume">立即添加简历</span> </p>
                    </div>
                  </div>

                  <!-- 新增或编辑 -->
                  <addremuse v-else :type='remuseType'></addremuse>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <footerBox></footerBox>
    <el-dialog title="新增简历" :visible.sync="adddialogVisible" width="548px" :before-close="handleClose">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="简历名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adddialogVisible = false">取 消</el-button>
        <el-button type="success" @click="addresumeCommit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import addremuse from "./add"
  export default {
    name: 'home',
    components: {
      addremuse
    },
    data() {
      return {
        queryParams: {
          pageNum: 1,
          pageSize: 10,
        },
        total: 5,
        titleOne: 1,
        options: [{
          value: '选项1',
          label: '不限'
        }, {
          value: '选项2',
          label: '已读'
        }, {
          value: '选项3',
          label: '未读'
        }],
        value: '',
        tableData: [{
            con: '系统检测到您的简历信息完善度比较低,完善度较高的简历被企业查看到的几率更多，建议您尽快完善简历信息。',
            time: '2023-01-09 14:38:30',
            red: 1,
          },
          {
            con: '系统检测到您的简历信息完善度比较低,完善度较高的简历被企业查看到的几率更多，建议您尽快完善简历信息。',
            time: '2023-01-09 14:38:30',
            red: 2,
          }
        ],
        adddialogVisible: false,
        ruleForm: {
          name: '',
        },
        rules: {
          name: [{
            required: true,
            message: '请输入简历名称',
            trigger: 'blur'
          }, ],

        },
        show: true,
        list: [],
        // detail显示详情  add 新增
        remuseType: 'detail'
      }
    },

    beforeDestroy() {
      console.log("beforeDestroy")
    },
    methods: {
      handleDelete() {
        this.$confirm('是否确认删除此条简历?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      handleUpdate() {
        this.remuseType = 'detail'
        this.show = false
      },
      addresumeCommit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.remuseType = 'add'
            this.show = false;
            this.handleClose()
          } else {
            console.log('error submit!!');
            return false;
          }
        });

      },
      addresume() {
        this.adddialogVisible = true
      },
      handleClose() {
        this.ruleForm.name = ''
        this.adddialogVisible = false
      },
      getList() {

      },
      chose(e) {
        this.titleOne = e
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
    }
  }
</script>

<style lang="less" scoped>
  .recruitBtn {
    margin-bottom: 38px;

    li {
      width: 19%;
      padding-top: 9%;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }


  .incontentRight {
    margin-bottom: 48px;
    background: #FFFFFF;
    margin-top: 27px;

    .incRightTitle {
      height: 48px;
      padding: 0 8px;

      li {
        line-height: 48px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 48px;
        color: #333333;
        padding: 0 16px;
      }

      .activeLi {
        color: #AD9C72;

      }
    }

  }

  .incRightCon {
    padding: 16px;
  }

  .incRightCon .incRightConHot {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #FDF7ED;
    border-radius: 10px;

    p {
      &:first-child {
        width: 52px;
        flex-shrink: 0;
        text-align: center;
        // padding: 0 10px;
        height: 29px;
        background: linear-gradient(90deg, #F6A14B -1.91%, #F27E0B 100%);
        border-radius: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #FFFFFF;
        margin: 0 10px;
      }

      &:last-child {
        flex: 1;
        flex-shrink: 0;
        width: calc(100% - 60px);
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: #333333;
      }
    }
  }

  .incRightConUl {
    min-height: 480px;

    li {

      line-height: 48px;
      height: 48px;

      i {
        font-size: 26px;
        color: #AD9C72;
      }

      div {
        overflow: hidden;
        // max-width: 80%;
      }

      .content {
        width: 85%;
        color: #333333;
      }

      p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

      }

      .time {
        width: 100px;
        text-align: right;
        color: #999999;
      }

      .status {
        width: 72px;
        height: 24px;
        background: #FDF7ED;
        border-radius: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FF5202;
        text-align: center;
      }
    }

  }

  .miContent {
    background: #FFFFFF;
  }

  .miCtitle {
    padding: 20px 37px;
    position: relative;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    border-bottom: 1px solid #DFDFDF;
    position: relative;

    &::before {
      content: '';
      width: 4px;
      height: 20px;
      position: absolute;
      top: 20px;
      left: 25px;
      background: #C8B484;
    }
  }

  .miCbody {
    padding: 0px 20px 0;
    min-height: 529px;
  }

  .miCbodyBtn {
    cursor: pointer;
    padding: 0 10px;
    height: 32px;
    background: #FDF7ED;
    border-radius: 2px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #AD9C72;
    text-align: center;
    position: absolute;
    top: 16px;
    right: 20px;
  }

  .mibackBtn {
    border: 1px solid #e4e7ed;
    background-color: #fff;
    color: #666;
    letter-spacing: 1px;
  }

  .unread {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    span {
      color: #FF0000;
    }
  }

  .lookStatus {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    /deep/ .el-input__inner {
      border: 0 !important;
      background: #F7F8F9;
      height: 32px;
      line-height: 32px;
    }

    /deep/.el-select {
      width: 100px !important;
      margin: 0 10px !important;
    }

    /deep/ .el-input__icon {
      line-height: 32px;
    }
  }

  .msgTableBox {
    min-height: 400px;
    padding: 0px 0;

    /deep/ .el-table__header-wrapper th {
      background: #F7F8F9;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      border: 0 !important;
    }

    /deep/ tr:last-child td {
      border: 0 !important;
      cursor: pointer;
    }

    /deep/ .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      display: none !important;
    }

    /deep/ .el-table .el-table__cell {
      padding: 20px 0;
    }

    // /deep/  .el-table td.el-table__cell div{
    //   color: #333;
    // }
    /deep/ .el-button--text {
      color: #666666;
    }

  }

  .resumName {
    p {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      margin-right: 20px;
    }

    span {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }

  .resuMore {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;

    span {
      padding: 4px;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .emptyDiv {
    padding: 50px 0 0;

    p {
      text-align: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;

      span {
        color: #AD9C72;
        cursor: pointer;
      }
    }
  }
</style>
