<template>
  <div>
    <topNav></topNav>
    <div class="top-banner">
      <img src="../assets/image/banner2.png" alt="">
    </div>
    <div class="divBox">
      <div class="elrowBox">
        <el-row type="flex" justify="center">
          <el-col :lg="24" :xl="24" :md="22" :sm='23' :xs='23'>
            <div class="incontent flex justify_between align-start">
                <div class="el-carouselBox">
                  <el-carousel :interval="50000" arrow="never">
                      <el-carousel-item  v-for="item in 4" :key="item">
                        <img src="../assets/image/image25.png" alt="">
                      </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="incontentRight flex-1 ">
                  <ul class='incRightTitle flex'>
                    <li class="cursor-pointer">新闻资讯</li>
                  </ul>
                  <div class="borderB"></div>
                  <div class="incRightCon ">
                      <div class=" incRightConOpen cursor-pointer" @click="gotoDetail">
                        <h6 class="elOne">人力资源社会保障部办公厅关于进一步做好职称评审工作的通知</h6>
                        <p class="eltwo "> 收好这张图！享受个人养老金税收优惠政策三步走，收好这张图！享受个人养老金税收优惠政策三步走，收好这张图！享受个人养老金税收优惠政策三步走</p>
                      </div>
                      <ul class="incRightConUl">
                          <li class="flex cursor-pointer " v-for="item in newList" @click="gotoDetail">
                              <p class="elOne flex-1 hoverFont">{{item.name}}</p>
                              <p>{{item.time}}</p>
                          </li>
                      </ul>
                  </div>
                </div>
            </div>
          <ul class="infoTitle flex justify_around ">
            <p class="iborderb" :style="{left:ibleft}"></p>
            <li :class="titleIndex==1?'activeiLi':''" @click="choseTitle(1)"> 新闻资讯</li>
            <li :class="titleIndex==2?'activeiLi':''" @click="choseTitle(2)"> 公司新闻</li>
            <li :class="titleIndex==3?'activeiLi':''" @click="choseTitle(3)"> 政策信息</li>
            <li :class="titleIndex==4?'activeiLi':''" @click="choseTitle(4)"> 劳动法苑</li>
            <li :class="titleIndex==5?'activeiLi':''" @click="choseTitle(5)"> 求职攻略</li>
          </ul>
          <div class="infoMsgList">
            <p class="leftArrow el-icon-arrow-left" @click="leftArrowBtn"></p>
            <p class="rightArrow el-icon-arrow-right"  @click="rightArrowBtn"></p>
            <ul class="flex">
              <li class="cursor-pointer" @click="gotoDetail">
                <h5 class="eltwo">人力资源社会保障部办公厅关于进一步做好职称评审工作的通知</h5>
                <p class="eltwo">各省、自治区、直辖市及新疆生产建设兵团人力资源社会保障厅（局），国务院各部委、各直属机构人事部门...</p>
                <span>2023-01-05</span>
              </li>
              <li class="cursor-pointer" @click="gotoDetail">
                <h5 class="eltwo">人力资源社会保障部办公厅关于进一步做好职称评审工作的通知</h5>
                <p class="eltwo">各省、自治区、直辖市及新疆生产建设兵团人力资源社会保障厅（局），国务院各部委、各直属机构人事部门...</p>
                <span>2023-01-05</span>
              </li>
              <li class="cursor-pointer" @click="gotoDetail">
                <h5 class="eltwo">人力资源社会保障部办公厅关于进一步做好职称评审工作的通知</h5>
                <p class="eltwo">各省、自治区、直辖市及新疆生产建设兵团人力资源社会保障厅（局），国务院各部委、各直属机构人事部门...</p>
                <span>2023-01-05</span>
              </li>
              <li class="cursor-pointer" @click="gotoDetail">
                <h5 class="eltwo">人力资源社会保障部办公厅关于进一步做好职称评审工作的通知</h5>
                <p class="eltwo">各省、自治区、直辖市及新疆生产建设兵团人力资源社会保障厅（局），国务院各部委、各直属机构人事部门...</p>
                <span>2023-01-05</span>
              </li>
              <li class="cursor-pointer" @click="gotoDetail">
                <h5 class="eltwo">人力资源社会保障部办公厅关于进一步做好职称评审工作的通知</h5>
                <p class="eltwo">各省、自治区、直辖市及新疆生产建设兵团人力资源社会保障厅（局），国务院各部委、各直属机构人事部门...</p>
                <span>2023-01-05</span>
              </li>
              <li class="cursor-pointer" @click="gotoDetail">
                <h5 class="eltwo">人力资源社会保障部办公厅关于进一步做好职称评审工作的通知</h5>
                <p class="eltwo">各省、自治区、直辖市及新疆生产建设兵团人力资源社会保障厅（局），国务院各部委、各直属机构人事部门...</p>
                <span>2023-01-05</span>
              </li>
            </ul>
          </div>
          <div class="lookAll" @click="gotoMore">查看全部 <i class="el-icon-arrow-right"></i> </div>
          </el-col>
        </el-row>
      </div>
    </div>




    <footerBox></footerBox>
  </div>
</template>

<script>
  import {
    WOW
  } from 'wowjs'
  export default {
    name: 'home',
    components: {

    },
    data() {
      return {
        value: [],
        options: [{
          value: 'zhinan',
          label: '指南',           children: [{             value: 'shejiyuanze',
             label: '设计原则',             children: [{
               value: 'yizhi',
               label: '一致'
             }, {
              value: 'fankui',
              label: '反馈' ,
              }, {
              value: 'xiaolv',
              label: '效率',
              }, {
              value: 'kekong',
              label: '可控'             }]
          }, {
            value: 'daohang',
            label: '导航',
            children: [{               value: 'cexiangdaohang',
               label: '侧向导航'
             }, {
              value: 'dingbudaohang',               label: '顶部导航',
               }]
          }]         }, {
           value: 'zujian',           label: '组件',
          children: [{
            value: 'basic',
            label: 'Basic',
          },
          {
            value: 'navigation',             label: 'Navigation',
          }]
        }, {           value: 'ziyuan',
           label: '资源',
           children: [{
             value: 'axure',             label: 'Axure Components'           }, {             value: 'sketch',
             label: 'Sketch Templates'           }, {             value: 'jiaohu',
             label: '组件交互文档'           }]
        }],
        value:null,
        keyWords:"",
        newList:[
        {name:'人力资源社会保障部办公厅关于进一步做好职称评审工作的通知',time:'2023-01-03'},
        {name:'收好这张图！享受个人养老金税收优惠政策三步走',time:'2023-01-03'},
        {name:'把优秀人才集聚到党和人民事业中来（新论）',time:'2023-01-03'},
        {name:'开始确认，可以退钱了',time:'2023-01-03'},
        {name:'收好这张图！享受个人养老金税收优惠政策三步走',time:'2023-01-03'}],
        titleIndex:1,
        ibleft:"18%"
      }
    },

    watch: {

    },
    created() {},

    mounted() {
      new WOW().init();

    },

    methods: {
      gotoMore(e){
        this.$router.push({path:'/moreInfo',query:{id:e}})
      },
      gotoDetail(e){
        this.$router.push({path:'/detail',query:{id:11}})
      },
      leftArrowBtn(){
          if(this.titleIndex>1&&this.titleIndex<=5){
            this.titleIndex--
            this.choseTitle(this.titleIndex)
          }
      },
      rightArrowBtn(){
        if(this.titleIndex>=1&&this.titleIndex<5){
          this.titleIndex++
          this.choseTitle(this.titleIndex)
        }
      },
      choseTitle(e){
          this.titleIndex=e;
          if(e==1){
              this.ibleft="18%"
          }else{
            let num=((e-1)*16)+18
             this.ibleft=num+'%'
          }

      },
      handleChange(){

      }
    }
  }
</script>

<style lang="less" scoped>
  .insearchBox{
      width: 100%;
      height: 56px;
      border: 1px solid #ad9c72;
      border-radius: 4px;
      padding:0 8px 0 28px;
      box-sizing: border-box;
      margin-bottom: 35px;
      background-color: #fff;
      position: relative;
      /deep/ .el-input__inner{
        border: 0!important;
      }
  }
  .insearchBox::after{
    content: "";
        position: absolute;
        top:24px;
        left: 10px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #AD9C72;
      }
      .commitBtn{
          width: 134px;
          height: 40px;
          background: #AD9C72;
          box-shadow: 1px 2px 3px rgba(255, 237, 194, 0.62);
          border-radius: 4px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 40px;
          color: #FFFFFF;
          text-align: center;
          &:hover{
            opacity: 0.7;
          }
      }
      .incontent{

      }
      .el-carouselBox{
          width: 43%;
          height: 336px;
          flex-shrink:0;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
           /deep/  .el-carousel__container{
            height: 336px;
          }
          /deep/ .el-carousel__button{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);
          }
          /deep/ .el-carousel__indicators .is-active .el-carousel__button{
            width: 32px;
             height: 8px;
            border-radius: 40px;
             background: rgba(255, 255, 255,1);
          }
      }

      .incontentRight{
         flex-shrink:1;
        margin-left: 20px;
        background: #FFFFFF;
        width: 55%;
        .incRightTitle{
            height: 48px;
            li{
              line-height: 48px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 48px;
              color: #333333;
              padding: 0 16px;
            }
            .activeLi{
              color: #AD9C72;

            }
        }

      }
      .incRightCon{
          padding: 16px;
          height: 254px;
      }
       .incRightCon .incRightConOpen{
         margin-bottom: 10px;
         &:hover{
           h6{
             color: #AD9C72;
           }
         }
          h6{
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 28px;
              color: #333333;

          }
          p{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #666666;
            text-indent: 2em;
            margin-top: 6px;
          }
       }
      .incRightConUl{
        li{
          padding-left: 20px;

          position: relative;
          &::before{
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            top:14px;
            left: 0px;
            background: #C8B484;
          }
          p{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 34px;
            &:first-child{
                color: #333333;
            }
            &:last-child{
              width:100px ;
              text-align: right;
               color: #999999;
            }
          }
        }

      }
      .infoTitle{
        border-bottom: 1px solid #E0E0E0;
        line-height: 78px;
        height: 78px;
        padding: 0 10%;
        margin-top:37px;
        position: relative;
        .iborderb{
          position: absolute;
          bottom:0;
          left: 18%;
          margin-left: -23px;
          width: 46px;
          height: 4px;
          background: #AD9C72;
          transition: 0.5s;
        }
        li{
          cursor: pointer;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          color: #666666;
        }
        .activeiLi{
          color: #AD9C72;
        }
      }
      .infoMsgList{
        padding: 30px 0;
        position: relative;
        .leftArrow{
          cursor: pointer;
          position: absolute;
          top: 195px;
          left: -70px;
          font-size: 70px;
          color: #AD9C72;
        }
        .rightArrow{
          cursor: pointer;
          position: absolute;
          top: 195px;
          right: -70px;
          font-size: 70px;
          color: #AD9C72;
        }

        ul{
          flex-wrap: wrap;
        }
        li{
          padding: 15px;
          background-color: #fff;
          width: 29.5%;
          &:hover{
            background-color: #AD9C72;
            h5{
              color: #fff;
            }
            p{
               color: #fff;
            }
            span{
               color: #fff;
            }
          }
          &:nth-child(3n-1){
            margin: 18px 1.84%;
          }
          h5{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #333333;
          }
          p{
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          margin: 15px 0;
          }
          span{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #999999;
          }
        }
      }

      .lookAll{
        cursor: pointer;
        text-align: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #333333;
        padding:10px 0 40px;

      }
</style>
