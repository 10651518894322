<template>
  <div>
    <topNav>
      <div class="sub-nav-bar flex-row align-center">
        <img class="sub-nav-icon" src="../assets/img-new/icon-home.png" />
        <router-link class="sub-nav-item active" to="/home">> 求职招聘 ></router-link>
        <router-link class="sub-nav-item active" to="/home"> 招聘考试 ></router-link>
        <span class="sub-nav-item">个人中心</span>
      </div>
    </topNav>

    <div class="divBox">
<!--      <mainNav></mainNav>-->
      <div class="elrowBox">
        <div class="flex justify_between align-start apply-content">
          <mineMenu></mineMenu>
          <div class="miContent flex-1">
            <div class="miCtitle">我的申请</div>
            <div class="miCbody">
              <div class="msgTableBox">
                <el-row class="header">
                  <el-col :span="10">
                    <div class="th">招聘信息</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="th text-center">申请岗位</div>
                  </el-col>
                  <el-col :span="4">
                    <div class="th text-center">申请时间</div>
                  </el-col>
                  <el-col :span="4">
                    <div class="th text-center">状态</div>
                  </el-col>
                </el-row>
                <div v-if="tableData && tableData.length>0">
                  <div v-for="(row,index) in tableData" :key="index" class="cell-item">
                    <el-row :gutter="10" type="flex" justify="center">
                      <el-col :span="10">
                        <div class="td">{{row.recruitmentTitle}}</div>
                      </el-col>
                      <el-col :span="6">
                        <div class="td text-center">
                          <p v-if="row.deptName">{{row.deptName}}</p>
                          <p>{{row.postTitle}}</p>
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div class="td  text-center">{{row.applyTime}}</div>
                      </el-col>
                      <el-col :span="4">
                        <div class="td text-center">
                          <span v-if="row.employStatus==1" style="color:#F27E0B ;">已录取</span>
                          <el-popover v-else-if="row.employStatus==2"
                                      placement="top-start"
                                      title="未录取原因"
                                      width="300"
                                      trigger="hover"
                                      :content="row.employReason">
                            <p slot="reference" style="color: #ff4949;cursor: pointer;">未录取,点击查看原因</p>
                          </el-popover>

                          <span v-else-if="row.interviewStatus==1" style="color:#F27E0B ;">已面试</span>

                          <span v-else-if="row.reviewStatus==1" style="color:#F27E0B ;">资格复审通过</span>
                          <el-popover v-else-if="row.reviewStatus==2"
                                      placement="top-start"
                                      title="未录取原因"
                                      width="300"
                                      trigger="hover"
                                      :content="row.reviewReason">
                            <p slot="reference" style="color: #ff4949;cursor: pointer;">资格复审不通过,点击查看原因</p>
                          </el-popover>


                          <span v-else-if="row.reviewStatus==3" style="color:#F27E0B ;">已提交资格复审资料</span>

                          <span v-else-if="row.writtenStatus==1" style="color:#F27E0B ;">已笔试</span>
                          <el-popover v-else-if="row.writtenStatus==2"
                                      placement="top-start"
                                      title="异常原因"
                                      width="300"
                                      trigger="hover"
                                      :content="row.remark?row.remark:'-'">
                            <p slot="reference" style="color: #ff4949;cursor: pointer;">笔试异常,点击查看原因</p>
                          </el-popover>

                          <span v-else-if="row.payStatus==2 && row.postStatus==2" style="color:#F27E0B ;">已退费，岗位已取消</span>
                          <span v-else-if="row.payStatus==1 && row.postStatus==2" style="color:#F27E0B ;">已缴费，岗位已取消</span>

                          <span v-else-if="row.payStatus==2" style="color:#F27E0B ;">已退费</span>
                          <span v-else-if="row.payStatus==1" style="color:#F27E0B ;">已缴费</span>


                          <span v-else-if="row.checkStatus==1" style="color:#F27E0B ;">报名审核通过</span>

                          <span v-else-if="row.checkStatus==1 && (!row.payStatus) && row.isPayment==1 && row.examinationStatus!=1" style="color:#F27E0B ;">待缴费</span>

                          <el-popover v-else-if="row.checkStatus==2"
                                      placement="top-start"
                                      title="不通过原因"
                                      width="300"
                                      trigger="hover"
                                      :content="row.checkReason">
                            <p slot="reference" style="color: #ff4949;cursor: pointer;">报名审核未通过,点击查看原因</p>
                          </el-popover>

                          <span v-else style="color:#F27E0B ;">报名成功</span>
                        </div>
                      </el-col>
                    </el-row>

                    <div style="text-align: right;margin-top: 15px;">
                      <el-button v-if="row.checkStatus==2 && row.isEnter ==1" size="mini" type="warning" @click="goEdit(row)">修改信息</el-button>
                      <el-button v-else-if="row.checkStatus==1 && (!row.payStatus) && row.isPayment ==1&& row.examinationStatus!=1" size="mini" type="warning" @click="showPayment(row)">在线缴费</el-button>
                      <el-button v-else-if="row.checkStatus==1 && (row.payStatus || row.examinationStatus==1) && row.postStatus==2 && row.isEnter && !row.isNew" size="mini" type="warning" @click="changePost(row)">换岗报名</el-button>
                      <el-button v-else-if="row.checkStatus==1 && row.hasTicket && row.isTicket==1" size="mini" type="warning" @click="downloadTicket(row)">下载准考证</el-button>

                      <el-button v-if="row.writtenStatus&&row.isWritten==1" size="mini" type="warning" @click="showWrittenScore(row)">查看笔试成绩</el-button>
                      <el-button v-if="row.writtenStatus&&row.isApplyDown==1" size="mini" type="warning" @click="exportWord1(row)">下载报名表</el-button>
                      <el-button v-if="row.checkStatus==1&&(row.writtenQualified==1||row.examinationStatus==1)&&row.isReview==1 && !row.reviewStatus" size="mini" type="warning" @click="showUpload(row)">上传资格复审资料</el-button>

                      <el-button v-if="row.reviewStatus==1&&row.isScore==1" size="mini" type="warning" @click="showScore(row)">查看成绩</el-button>
                      <el-button v-if="row.waitStatus==1 && row.isWork==1" size="mini" type="warning" @click="showWork(row)">查看入职信息</el-button>
                      <el-button v-if="row.reviewStatus==1 && row.isInterviewTicket==1" size="mini" type="warning" @click="downloadInterview(row)">下载面试准考证</el-button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <el-row>
                    <div class="empty-table">暂无数据</div>
                  </el-row>
                </div>
              </div>
              <div class="flex justify_end">
                <pagination :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
                            @pagination="getList" />
              </div>
            </div>
          </div>
        </div>
        </el-col>
        </el-row>
      </div>
    </div>
    <footerBox></footerBox>
    <el-dialog title="在线缴费" :visible.sync="payVisible" :close-on-click-modal="false" width="600px" center @close='closePayment'>
      <div v-if="order" style="padding:20px 60px; display: flex;flex-direction: column;align-items: center;">
        <div style="font-size:26px;font-weight: 700; text-center;color:#333333">报名费：{{order.tranAmt}}元</div>
        <div style="font-size: 12px; margin-top: 15px;margin-bottom: 25px;">报名费用依据鄂价费规〔2011〕100号</div>
        <div style="padding: 20px;background-color: #F4f4f5;">
          <div style="width: 200px;height: 200px;">
            <QrcodeVue :value="order.payUrl" :size="200" qid="paymentid"></QrcodeVue>
          </div>

        </div>
        <div style="font-size: 16px;margin-top: 20px;text-center">微信/支付宝扫一扫付款</div>
      </div>
    </el-dialog>

    <el-dialog title="上传资格复审资料" :visible.sync="reviewVisible" :close-on-click-modal="false" width="900px" center @close='closeUpload'>
      <el-form ref="reviewForm" :model="reviewForm" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-col :span="24" v-for="(item, index) in reviewForm.reviewFileList"  :key="index">
            <el-form-item
              :label="item.title"
              :prop="'reviewFileList.' + index + '.files'"
              :rules="item.rule"
            >
              <FileUpload v-model="item.files" :fileType="fileType"></FileUpload>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right;">
        <el-button @click="closeUpload">取消</el-button>
        <el-button type="primary" @click="saveUpload">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="笔试成绩信息" :visible.sync="writtenScoreVisible" :close-on-click-modal="true" width="800px" center @close='closeWrittenScore'>
      <div>
        <table class="score-table"  border="2" cellspacing="0">
          <tr>
            <th colspan="2">{{writtenScore.recruitApply.title}}笔试</th>
          </tr>
          <tr>
            <th colspan="2">
              <span style="color: red;">考生信息</span>
            </th>
          </tr>
          <tr>
            <td width="200">准考证号</td>
            <td>{{writtenScore.recruitApply.sn}}</td>
          </tr>
          <tr>
            <td>姓名</td>
            <td>{{writtenScore.recruitApply.name}}</td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td>{{writtenScore.recruitApply.idNumber}}</td>
          </tr>
          <tr>
            <td>报考单位</td>
            <td>{{writtenScore.recruitApply.deptName}}</td>
          </tr>
          <tr>
            <td>报考岗位</td>
            <td>{{writtenScore.recruitApply.postTitle}}</td>
          </tr>
          <tr>
            <th colspan="2">
              <span style="color: red;">成绩信息</span>
            </th>
          </tr>
          <template v-if="writtenScore.recruitScores&&writtenScore.recruitScores.length>0">
            <tr v-for="(item,index) in writtenScore.recruitScores" :key="index" v-if="item.scoreType==1">
              <td>{{item.subject}}</td>
              <td>{{item.score}}</td>
            </tr>
          </template>
          <!--        <tr >-->
          <!--          <td>加分</td>-->
          <!--          <td>{{writtenScoreAdd}}</td>-->
          <!--        </tr>-->
          <tr>
            <td>笔试最终成绩</td>
            <td>{{writtenScore.recruitApply.writtenScore}}</td>
          </tr>
          <tr>
            <td>岗位名次</td>
            <td>{{writtenScore.recruitApply.writtenRank}}</td>
          </tr>
          <tr>
            <td>是否入围</td>
            <td v-if="writtenScore.recruitApply.reviewStatus && writtenScore.recruitApply.reviewStatus==1">是</td>
            <td v-if="!writtenScore.recruitApply.reviewStatus || writtenScore.recruitApply.reviewStatus==2">否</td>
          </tr>
          <tr>
            <!--          <td colspan="2">-->
            <!--            <p style="color: red;text-align: right;">-->
            <!--              &lt;!&ndash;              成绩为-2表示考生违纪&ndash;&gt;-->
            <!--            </p>-->
            <!--          </td>-->

            <td>备注</td>
            <td >{{writtenScore.recruitApply.remark}}</td>

          </tr>
        </table>
      </div>
    </el-dialog>

    <el-dialog title="综合成绩信息" :visible.sync="scoreVisible" :close-on-click-modal="true" width="800px" center @close='closeScore'>
      <div>
        <table class="score-table"  border="2" cellspacing="0">
          <!-- <tr>
            <th colspan="2">{{writtenScore.recruitApply.title}}综合成绩</th>
          </tr> -->
          <tr>
            <th colspan="2">
              <span style="color: red;">考生信息</span>
            </th>
          </tr>
          <tr>
            <td width="200">准考证号</td>
            <td>{{scoreData.sn}}</td>
          </tr>
          <tr>
            <td>姓名</td>
            <td>{{scoreData.name}}</td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td>{{scoreData.idNumber}}</td>
          </tr>
          <tr>
            <td>报考单位</td>
            <td>{{scoreData.deptName}}</td>
          </tr>
          <tr>
            <td>报考岗位</td>
            <td>{{scoreData.postTitle}}</td>
          </tr>
          <tr>
            <th colspan="2">
              <span style="color: red;">成绩信息</span>
            </th>
          </tr>
          <tr>
            <td>笔试成绩</td>
            <td>
              <span v-if="scoreData.examinationStatus==1">免笔试</span>
              <span v-else>{{scoreData.writtenScore}}</span>
            </td>
          </tr>
          <tr>
            <td>面试成绩</td>
            <td>{{scoreData.interviewScore}}</td>
          </tr>
          <tr>
            <td>综合成绩</td>
            <td>{{scoreData.score}}</td>
          </tr>
          <tr>
            <td>名次</td>
            <td>{{scoreData.scoreRank}}</td>
          </tr>
          <tr>
            <td>是否入围</td>
            <td>
              <span v-if="scoreData.waitStatus==1">是</span>
              <span v-if="scoreData.waitStatus==2">否</span>
            </td>
          </tr>
          <tr>
            <td>备注</td>
            <td>{{scoreData.interviewRemark}}</td>
          </tr>
        </table>
      </div>
    </el-dialog>


    <el-dialog title="入职信息" :visible.sync="workOpen" :close-on-click-modal="true" width="1000px" center>
      <div class="rich-content">
        <div v-html="workData.workContent"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import JSZip from 'jszip';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import {
  saveAs
} from "file-saver";
import ImageModule from 'docxtemplater-image-module-free'
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
function null2Empty(obj){
  for(let i in obj){
    obj[i] = obj[i] ===null ? '' :obj[i];
  }
  return obj;
}
import {parseTime} from '@/utils/index.js'
import {doDecryptData} from '@/utils/auth.js'
import {getMemberApplyList ,createOrder ,checkPayment ,getTicket ,getInterview,
  getReviewFile,getWorkInfo, uploadReviewFile ,getWrittenScore ,getApplyInfo,
  getScoreData,generationApply} from '@/api/login.js'
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'home',
  components: {
    QrcodeVue
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      titleOne: 1,
      options: [{
        value: '选项1',
        label: '不限'
      }, {
        value: '选项2',
        label: '已读'
      }, {
        value: '选项3',
        label: '未读'
      }],
      value: '',
      tableData: [],
      payVisible:false,
      order:null,

      reviewVisible:false,
      reviewForm:{
        reviewFileList:[]
      },
      fileType:['pdf','png','jpeg','jpg'],

      writtenScoreVisible:false,
      writtenScore:{
        recruitApply:{},
        recruitScores:[]
      },
      scoreVisible:false,
      scoreData:{},

      workOpen:false,
      workData:{}
    }
  },
  computed:{
    writtenScoreAdd(){
      let add='';
      if(this.writtenScore.recruitScores && this.writtenScore.recruitScores.length>0){
        let item =this.writtenScore.recruitScores.find(item=>{
          return item.scoreType==3;//笔试加分
        })
        if(item){
          add =item.score;
        }
      }
      return add;
    }
  },
  created() {
    this.getList()
  },
  beforeDestroy() {
    this.closeTimer()
  },
  methods: {
    getList() {
      getMemberApplyList().then(res=>{
        if(res.code==200){

          this.tableData ==doDecryptData(res.data) ;

          this.total =this.tableData.length
        }
      })
    },
    chose(e) {
      this.titleOne = e
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    goEdit(row){
      this.$router.push({
        'path':'/applyPost',
        query:{id:row.recruitmentId,applyId:row.id}
      })
    },
    showPayment(row){
      let data={
        applyId:row.id
      }
      if(this.loading){
        return;
      }
      this.loading=true;
      createOrder(data).then(res=>{
        this.loading=false;
        if(res.code ==200){
          this.payVisible=true;
          this.order =res.data;
          this.startTimer();
        }else{
          this.$message.warning(res.msg ?res.msg :'操作失败');
        }
      }).catch(res=>{
        this.loading=false;
      })
    },
    closePayment(){
      this.payVisible=false;
      this.order=null;
      this.closeTimer();
    },
    startTimer(){
      this.closeTimer();
      this.timer =setInterval(()=>{
        if(this.checking){
          return;
        }
        if(!this.order){
          return;
        }
        this.checking=true;
        checkPayment(this.order.id).then(res=>{
          this.checking=false;
          if(res.data && res.data.payStatus==1){
            this.closePayment();
            this.getList();
            this.$message.success('缴费成功');
          }
        })
      },1000)
    },
    closeTimer(){
      if(this.timer){
        clearInterval(this.timer);
        this.timer =null;
      }
    },
    changePost(row){
      this.$router.push({
        'path':'/applyPost',
        query:{id:row.recruitmentId,oldId:row.id}
      })
    },
    downloadTicket(row){
      if(this.downloading){
        return
      }
      this.downloading=true;
      getTicket(row.id).then(res=>{
        this.downloading=false;
        if(res.code==200 && res.msg){
          window.open(res.msg);
        }
      })
    },
    downloadInterview(row){
      if(this.downloading){
        return
      }
      this.downloading=true;
      getInterview(row.id).then(res=>{
        this.downloading=false;
        if(res.code==200 && res.msg){
          window.open(res.msg);
        }
      })
    },

    showWrittenScore(row){
      let formData={
        applyId:row.id
      }
      getWrittenScore(formData).then(res=>{
        if(res.code==200){
          this.writtenScore =doDecryptData(res.data);
        }
      })
      this.writtenScoreVisible=true;
    },
    closeWrittenScore(){
      this.writtenScoreVisible=false;
    },
    showScore(row){
      let applyId = row.id;
      this.scoreData={};
      getScoreData(applyId).then(res=>{
        if(res.code==200){
          this.scoreData =doDecryptData(res.data);
        }
      })
      this.scoreVisible=true;
    },
    closeScore(){
      this.scoreVisible=false;
    },
    showUpload(row){
      this.reviewForm.reviewFileList =[];
      this.reviewForm.id =row.id;
      getReviewFile(row.recruitmentId).then(res=>{
        if(res.code=200){
          try{
            let fileList =JSON.parse(res.msg)
            fileList.forEach(item=>{
              item.files=null;
              item.rule=[]
              if(item.required==1){
                item.rule=[{required: true,trigger: 'change',message: '请上传'+item.title}, ]
              }
            })
            this.reviewForm.reviewFileList =fileList;
          }catch(e){

          }
        }
      })
      this.reviewVisible=true;
    },
    closeUpload(){
      this.reviewVisible=false;
    },
    saveUpload(){
      this.$refs.reviewForm.validate(valid=>{
        if(valid){
          let formData={
            id:this.reviewForm.id,
            qualificationReview:JSON.stringify(this.reviewForm.reviewFileList)
          }
          uploadReviewFile(formData).then(res=>{
            if(res.code==200){
              this.$message.success('上传成功')
              this.getList()
              this.closeUpload()
            }else{
              this.$messge.error(res.msg ? res.msg :'上传失败');
            }
          })
        }
      })
    },
    exportWord(row){
      let applyId =row.id;
      getApplyInfo(applyId).then(res=>{
        if(res.code==200){
          res.data =res.data.data;

          this.applyData =res.data;
          this.applyData.postTitle =this.applyData.post;

          this.workAchievement = res.data.workAchievement ?JSON.parse(res.data.workAchievement) :{},
            this.recommend =  res.data.recommendObj ? res.data.recommendObj :{};
          this.recommend.motive = this.recommend.motive ?this.recommend.motive.split(',') :[];
          this.relationList =res.data.homeJson ? JSON.parse(res.data.homeJson):[]
          this.eduList = res.data.eduListObj ? res.data.eduListObj:[]
          this.workList =res.data.workHistory ?JSON.parse(res.data.workHistory):[]


          let  workAchievement={
            work: null,
            prize: null,
            address: null,
            unitType: [],
            scaleNum: null,
            saleNum: null,
            companyNum: null,
            staffNum: null,
            product: null,
            leaderName: null,
            leaderPhone: null,
            nowSalary: null,
            expectSalary: null,
            ...this.workAchievement,
            unitTypeStr:this.workAchievement.unitType ?this.workAchievement.unitType.join('，') :'',
          }
          workAchievement =null2Empty(workAchievement);
          let recommend={
            quality: null,
            motive: [],
            motiveCause: null,
            hobbiesSpecialties: null,
            isKinsfolk: null,
            isKinsfolkCause: null,
            earliestTime: null,
            ...this.recommend,
            isKinsfolk:this.recommend.isKinsfolk==1?'是':(this.recommend.isKinsfolk==2?'否':''),
          }
          recommend =null2Empty(recommend);
          let memberRelationData='';
          this.relationList.forEach(item=>{
            memberRelationData += [item.relation||'',item.name||'',item.job||'',item.phone||''].join('    ') + "\n"
          })

          let eduList=[];
          this.eduList.forEach(item=>{
            eduList.push({
              schoolName:'',
              isFull:'',
              subject:'',
              record:'',
              degree:'',
              ...null2Empty(item),
              time: (item.startTime ? parseTime(item.startTime ,'{y}-{m}-{d}') :'') +'至'+ (item.endTime ? parseTime(item.endTime ,'{y}-{m}-{d}') :''),
              isFull: item.isFull ==1 ?'是' :(item.isFull ==2 ?'否' :'')
            })
          })

          let workList=[];
          this.workList.forEach(item=>{
            workList.push({
              ...null2Empty(item),
              time: (item.startTime ? parseTime(item.startTime ,'{y}-{m}-{d}') :'') +'至'+ (item.endTime ? parseTime(item.endTime ,'{y}-{m}-{d}') :''),
            })
          })

          let applyData={
            avatar: null,
            name: null,
            phone: null,
            postId: null,
            postTitle:null,
            idNumber: null,
            birthTime:null,
            sex: null,
            domicilePlace: null,
            email: null,
            marryStatus: null,
            politics: null,
            nation: null,
            mailAddress: null,
            postalCode: null,
            partyTime: null,
            professional:null,
            workQualification: null,
            nowAddress: null,
            height: null,
            driverStatus: null,
            health: null,
            fertility: null,
            isFreshman:null,
            homeJson: [],
            workHistory: [],
            workAchievement: {
              work: null,
              prize: null,
              address: null,
              unitType: [],
              scaleNum: null,
              saleNum: null,
              companyNum: null,
              staffNum: null,
              product: null,
              leaderName: null,
              leaderPhone: null,
              nowSalary: null,
              expectSalary: null
            },
            recommend: {
              quality: null,
              motive: [],
              motiveCause: null,
              hobbiesSpecialties: null,
              isKinsfolk: null,
              isKinsfolkCause: null,
              earliestTime: null,
            },
            relationList: null,
            workList: null,
            eduList: null,
            recruitmentTitle:null,
            ...this.applyData
          }

          let examinationMap ={
            0:{label:'不申请',value:0},
            1:{label:'申请免笔试',value:1},
            2:{label:'申请笔试加分',value:2},
          }
          let examinationDesc='';
          if(examinationMap[this.applyData.examinationStatus]){
            examinationDesc = examinationMap[this.applyData.examinationStatus].label;
            if(this.applyData.examinationStatus==2){
              examinationDesc =examinationDesc +'(' +this.applyData.writtenScore2+'分)';
            }
          }

          let renderData={
            ...null2Empty(applyData),
            sex:this.applyData.sex==1 ?'男' :(this.applyData.sex==2?'女':''),
            isFreshman: this.applyData.isFreshman==1 ?'是':'否',

            memberRelationData:memberRelationData,
            eduList:eduList,
            workList:workList,
            workAchievement:workAchievement,
            recommend:recommend,
            examinationDesc:examinationDesc
          }
          let isAsync=false;
          if (renderData.avatar) {
            isAsync = true;
          }
          let tmpName='apply2';
          let fileName=this.applyData.postTitle + '-' +this.applyData.name ;
          let task = this.renderDoc(renderData ,tmpName ,fileName ,true ,isAsync)
        }
      });

    },
    exportWord1(row){
      generationApply(row.id).then(res=>{
        if(res.code=200 && res.msg){
          window.open(res.msg);
        }
      })
    },
    renderDoc(renderData ,tmpName ,fileName ,isDown=true ,isAsync=false) {
      let _self = this;
      let file = "/static/word/"+tmpName+".docx"
      let promise = new Promise((resolve ,reject)=>{
        loadFile(file, function(
          error,
          content
        ) {
          if (error) {
            throw error;
          }
          var opts = {}
          opts.centered = false;
          opts.getImage = function(tagValue, tagName) {

            return new Promise(function(
              resolve,
              reject
            ) {
              PizZipUtils.getBinaryContent(
                tagValue,
                function(error, content) {
                  if (error) {
                    // return reject(error);
                  }
                  return resolve(content);
                }
              );
            });
          }
          opts.getSize = function(img, tagValue, tagName) {
            // FOR FIXED SIZE IMAGE :
            return [100, 120];

            // FOR IMAGE COMING FROM A URL (IF TAGVALUE IS AN ADRESS) :
            // To use this feature, you have to be using docxtemplater async
            // (if you are calling setData(), you are not using async).
            return new Promise(function(resolve, reject) {
              var image = new Image();
              image.src = url;
              image.onload = function() {
                resolve([image.width, image.height]);
              };
              image.onerror = function(e) {
                console.log('img, tagValue, tagName : ', img, tagValue, tagName);
                alert("An error occured while loading " + tagValue);
                reject(e);
              }
            });
          }


          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            modules: [new ImageModule(opts)]
          });

          let title = fileName;
          const generateWrod = function() {
            const out = doc.getZip().generate({
              type: "blob",
              mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            if(isDown){
              saveAs(out, title + ".docx");
            }else{
              return out;
            }
          }

          try {
            if (isAsync) {
              doc.renderAsync(renderData).then(function() {
                let out= generateWrod();
                if(!isDown){
                  _self.zipFile(title + ".docx" ,out ,{binary:true})
                  resolve();
                }
              });
            } else {
              doc.render(renderData)
              let out= generateWrod();

              if(!isDown){
                _self.zipFile.file(title + ".docx" ,out ,{binary:true})
                resolve();
              }
            }

          } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                }, {});
              }
              return value;
            }
            console.log(JSON.stringify({
              error: error
            }, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function(error) {
                  return error.properties.explanation;
                })
                .join("\n");
              console.log("errorMessages", errorMessages);
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }

        });
      })
      return promise;
    },
    showWork(row){
      getWorkInfo(row.recruitmentId).then(res=>{
        if(res.code=200){
          this.workData =res.data;
          this.workOpen=true;
        }
      })

    }
  }
}
</script>

<style lang="less" scoped>
.recruitBtn {
  margin-bottom: 38px;

  li {
    width: 19%;
    padding-top: 9%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


.incontentRight {
  margin-bottom: 48px;
  background: #FFFFFF;
  margin-top: 27px;

  .incRightTitle {
    height: 48px;
    padding: 0 8px;

    li {
      line-height: 48px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 48px;
      color: #333333;
      padding: 0 16px;
    }

    .activeLi {
      color: #AD9C72;

    }
  }

}

.incRightCon {
  padding: 16px;
}

.incRightCon .incRightConHot {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #FDF7ED;
  border-radius: 10px;

  p {
    &:first-child {
      width: 52px;
      flex-shrink: 0;
      text-align: center;
      // padding: 0 10px;
      height: 29px;
      background: linear-gradient(90deg, #F6A14B -1.91%, #F27E0B 100%);
      border-radius: 4px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
      color: #FFFFFF;
      margin: 0 10px;
    }

    &:last-child {
      flex: 1;
      flex-shrink: 0;
      width: calc(100% - 60px);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 29px;
      color: #333333;
    }
  }
}

.incRightConUl {
  min-height: 480px;

  li {

    line-height: 48px;
    height: 48px;

    i {
      font-size: 26px;
      color: #AD9C72;
    }

    div {
      overflow: hidden;
      // max-width: 80%;
    }

    .content {
      width: 85%;
      color: #333333;
    }

    p {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

    }

    .time {
      width: 100px;
      text-align: right;
      color: #999999;
    }

    .status {
      width: 72px;
      height: 24px;
      background: #FDF7ED;
      border-radius: 4px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #FF5202;
      text-align: center;
    }
  }

}

.miContent {
  background: #FFFFFF;
}

.miCtitle {
  padding: 20px 37px;
  position: relative;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  border-bottom: 1px solid #DFDFDF;

  &::before {
    content: '';
    width: 4px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: 25px;
    background: #C8B484;
  }
}

.miCbody {
  padding: 30px 40px 0;
  min-height: 529px;
}

.miCbodyBtn {
  cursor: pointer;
  width: 88px;
  height: 32px;
  background: #FDF7ED;
  border-radius: 2px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #AD9C72;
  text-align: center;
}

.unread {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;

  span {
    color: #FF0000;
  }
}

.lookStatus {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;

  /deep/ .el-input__inner {
    border: 0 !important;
    background: #F7F8F9;
    height: 32px;
    line-height: 32px;
  }

  /deep/.el-select {
    width: 100px !important;
    margin: 0 10px !important;
  }

  /deep/ .el-input__icon {
    line-height: 32px;
  }
}

.msgTableBox {
  min-height: 400px;
  /deep/ .el-table__header-wrapper th {
    background: #F7F8F9;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    border: 0 !important;
  }

  /deep/ tr:last-child td {
    // border: 0!important;
  }

  /deep/ .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    display: none !important;
  }

  /deep/ .el-table .el-table__cell {
    padding: 20px 0;
  }

  // /deep/  .el-table td.el-table__cell div{
  //   color: #333;
  // }

}


.timeLineBox {
  padding: 0px 10px;

  li {
    padding: 12px 10px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 9px;
      left: 0px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #FFFFFF;
      border: 2px solid #C8B484;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 6px;
      width: 1px;
      height: 100%;
      background: #D9D9D9;
    ;
      z-index: 1;
    }
  }
}

.WarningS {
  // text-decoration-line: underline;
  cursor: pointer;
  // border: 1px solid #f5c493;
  // color: #fff;
  line-height: 26px;
  height: 26px;
  padding: 0 10px;
  border-radius: 4px;
  display: inline-block;
  // background-color:#faf5e6;
  color: #F27E0B;
  // margin-left: 10px;
  &:hover{
    text-decoration-line: underline;
    // background-color: #F27E0B;
    // border: 1px solid #F27E0B;
    // color: #fff;
  }
  // color: #F40707;
}
.WarningG {
  // margin-left: 10px;
  display: inline-block;
  // text-decoration-line: underline;
  cursor: pointer;
  // border: 1px solid #c0c4cc;
  color: #909399;
  line-height: 26px;
  height: 26px;
  padding: 0 10px;
  border-radius: 4px;
  // color: #F40707;
  &:hover{
    text-decoration-line: underline;
    //   background-color: #c0c4cc;
    //   color: #fff;
    //   border: 1px solid #c0c4cc;
  }
}
.msgTableBox{
  .header{
    background: #F7F8F9;
    padding: 0 10px;
  }
  .th{
    background: #F7F8F9;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding: 20px 0;
  }
  .cell-item{
    padding: 20px 10px;
    border-bottom: 1px solid #EBEEF5;
    &:hover{
      background: #F7F8F9;
    }
  }
  .td{
    color: #606266;
  }
}
.text-center{
  text-align: center;
}

.empty-table{
  padding: 40px 30px;
  text-align: center;
  background-color: #fbfbfb;
  color: #999;
  font-size: 13px;
}
.score-table{
  width: 100%;
  color: #333;
  tr{
    border: 2px solid #666666;
  }
  td{
    padding: 5px;
    border-width: 2px;
  }
  th{
    padding: 5px;
  }
}
</style>
