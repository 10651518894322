<template>
  <div>
    <div class="SecondHeader">
      <div class="banner">
        <transition name="fade">
          <img :src="img" alt="">
        </transition>
        <div class="user">
          <div class="left">
          </div>
          <div class="right">
            <div class="btm">
              <div class="searchAll">
                <div class="bannervideo_bottom">
                  <input placeholder="" maxlength="32" type="text" v-model="keyword" style="font-size: 16px;">
                  </input>
                  <div class="fs">
                    <i class="el-icon-search" style="color: #33B8F5;" @click="goSearch"></i>
                  </div>

                </div>
              </div>
              <li class="login" style="color: #33B8F5;" v-if="!loginOn" @click="loginTo">登录/注册</li>
              <li v-else class="login-info-box">
                <div class="login-info" >
                  <img :src="userInfo.avatar ?userInfo.avatar: require('../assets/img-new/avatar.png')" alt="">
                  <div class="user-center">
                    <div class="u-center">个人中心</div>
                    <div class="hy">欢迎您 ! {{ userInfo.phone }}</div>
                  </div>
                </div>
                <div class="dropdownCard">
                  <div class="tag">

                  </div>
                  <div class="name">
                    <div>
                      <img :src="userInfo.memberType == 'enterprise' ? require('../assets/image/icon9.png') :
                                                  userInfo.memberType == 'person' ? require('../assets/image/icon7.png') : require('../assets/image/icon8.png')"
                           alt="">
                    </div>
                    <div>
                      {{
                        userInfo.userName && userInfo.userName != 'undefined' ? userInfo.userName :
                          userInfo.name
                      }}
                    </div>
                  </div>
                  <div class="item" @click="toAdmin">
                    {{
                      userInfo.memberType == 'enterprise' ? '企业中心' :
                        userInfo.memberType == 'person' ? '个人中心' : '工作台'
                    }}
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div class="item" @click="msgClick">
                    消息中心{{ msgCount ? `（${msgCount}）` : '' }}
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div class="item" @click="modifyPwd">
                    修改密码
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div class="btn" @click="logoutHome">
                    退出登录

                  </div>
                </div>
              </li>
            </div>
          </div>

        </div>
      </div>
      <navMenu :menuList="navList"></navMenu>
    </div>

    <div v-if="subNav" class="sub-nav flex-row align-center">
      <div class="elrowBox">
        <slot></slot>
      </div>
    </div>
  </div>

</template>

<script>
import register from "./register"
import login from "./login"
import navMenu from './navMenu.vue'
import axios from 'axios'
import {
  getToken, removeToken
} from '@/utils/auth'
import {
  getInfo, getMemus,loginout
} from "@/api/login.js"

export default {
  name: 'home',
  props: {
    subNav: {
      type: Boolean,
      default: true
    },
    img: {
      type: String,
      default: require('../assets/img-new/heaer-bg.png')
    }
  },
  components: {
    register,
    login,
    navMenu
  },
  data() {
    return {
      keyword: null,
      Sindex: 0,
      searchone: 1,
      openM: false,
      userInfo: {},

      navList: [],
      msgCount: 0
    }
  },
  computed: {
    loginOn() {
      return this.$store.state.haveLogin
    }
  },
  watch:{
    loginOn(){
      this.getCount()
    }
  },
  mounted() {
    this.loginOnto()
    this.getCount()
  },
  created() {
    this.getMenu();
  },
  methods: {
    getCount() {
      let that=this
      if (!that.loginOn) return

      let url = process.env.VUE_APP_BASE_SSO + '/prod-api/msg/data/unreadCount'
      let ssoToken = window.localStorage.getItem('ssoToken')
      axios.get(url, {headers: {'Authorization': 'Bearer ' + ssoToken}})
        .then(response => {
          that.msgCount = response.data.data;
        })
        .then(response => {
          // console.log(response,'response2')
          // this.interface2Data = response.data;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    },

    toAdmin() {
      window.location.href=process.env.VUE_APP_BASE_SSO + '/second/admin'
    },
    msgClick() {
      window.location.href=process.env.VUE_APP_BASE_SSO + '/second/MsgCenter'
    },
    //修改密码
    modifyPwd() {
      window.location.href=process.env.VUE_APP_BASE_SSO + '/second/ChangePassword'
    },
    //退出登录
    logoutHome() {
      let that=this
      console.log(1111)
      this.$confirm('退出登录后，您将返回光谷人首页。是否确认退出登录？', '退出登录', {
        confirmButtonText: '退出登录',
        cancelButtonText: '取消',
        // showCancelButton:false,
        type: 'info',
        iconClass: {
          'display': 'none'
        },
        customClass: 'confirmDivClass'

      }).then(() => {
        that.getAllOut()
        // loginout().then(res=>{
        //
        //
        // })



        // window.open(process.env.VUE_APP_BASE_SSO + '/second/ChangePassword')
        // https://www.ovc-talent.com/homePage
      }).catch(() => {

      });
    },
    getAllOut(){
      let url = process.env.VUE_APP_BASE_SSO + '/prod-api/logout'
      let ssoToken = window.localStorage.getItem('ssoToken')
      axios.post(url,{},{headers: {'Authorization': 'Bearer ' + ssoToken}})
        .then(response => {

          if(response.data.code==200){
            window.localStorage.removeItem("ssoToken")
            window.localStorage.removeItem("userToken")
            removeToken()
            window.location.href=process.env.VUE_APP_BASE_SSO + '/homePage'
          }

        })
        .then(response => {
          console.log(response,'response2')
          // window.location.reload()

        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    },
    toUrl(item) {
      let prefix = process.env.VUE_APP_BASE_SSO;
      if (item.isFrame == "0") {
        return item.path
      } else {
        let url = prefix;
        if (item.path.indexOf('/') === 0) {
          url = prefix + item.path;
        } else {
          url = prefix + '/' + item.path;
        }
        if (item.query) {
          url = url + (url.indexOf('?') == -1 ? '?' : ' &') + 'mode=' + item.query
        }
        return url;
      }
    },
    goUrl(item) {
      let url = this.toUrl(item);
      if (!localStorage.getItem('ssoToken') && url.indexOf('/bill') > -1) {
        this.showLogin()
      } else {
        window.location.href = this.toUrl(item);
      }
    },
    showLogin() {
      this.$confirm('请先以企业单位主体登录?', '提示', {
        confirmButtonText: '去登录',
        cancelButtonText: '取消',
        // showCancelButton:false,
        type: 'info',
        iconClass: {
          'display': 'none'
        }

      }).then(() => {
        this.loginTo();
      }).catch(() => {

      });
    },
    getMenu() {
      getMemus().then(res => {
        this.navList = res.data;
        // let navList=[
        //   {
        //     meta:{
        //       title:'首页',
        //     },
        //     path:'/homePage?title=首页'
        //   }
        // ];
        // if(res.code==200){
        //   navList=navList.concat(res.data)
        // }
        // navList.push({
        //   meta:{
        //     title:'关于我们',
        //   },
        //   path:'/second/AboutUs?title=关于我们'
        // })
        // this.navList =navList;
      })
    },
    loginOnto() {
      if (getToken()) {
        getInfo().then(res => {
          if (res.code == 200) {
            this.userInfo = res.data.user;
            this.$store.state.haveLogin = true;
            this.$store.state.loginType = res.data.user.memberType;
          } else {
            this.$store.state.haveLogin = false;
          }
        }).catch(e => {
          this.$store.state.haveLogin = false;
        })
      } else {
        this.$store.state.haveLogin = false;
      }

    },
    openMobile() {
      this.openM = !this.openM
    },
    handleCommand(command) {
      switch (command) {
        case "a":


          this.$router.push('/mineInfo')
          break;
        case "b":
          this.checkStatus(() => {
            this.$router.push('/uploadType')
          })
          break;
        case "c":
          this.getOut()
          break;
      }
    },
    checkStatus(callback) {
      getInfo().then(res => {
        if (!res.data.verifyStatus) {
          this.$message.error('请先完善个人信息');
          this.$router.push('/mineinfo')
        } else {
          callback(res.data);
        }
      })
    },
    getOut() {
      removeToken()
      this.$message.success("已退出登录");
      setTimeout(() => {
        location.reload()
      })
    },
    gotoMine() {
      this.$router.push('/apply')
    },
    loginTo() {
      // this.$message.error("跳转到登录");
      const url = encodeURIComponent(window.location.href);
      window.location.href = process.env.VUE_APP_BASE_SSO + "/LoginRegister?redirect=" + url + '&type=link';
    },
    registerTo() {
      this.$refs.registerBox.open()
    },
    goSearch() {
      if (this.keyword) {
        window.location.href = process.env.VUE_APP_BASE_SSO + "/second/Search?title=" + this.keyword;
      }
    }
  }
}
</script>

<style lang="less">

.sub-nav {
  height: 39px;
  background: #FBFBFB;
}

.SecondHeader {
  width: 100%;
  height: 150px;
  position: relative;
}

.banner {
  width: 100%;
  height: 100%;
  position: relative;
  background: url('../assets/img-new/heaer-bg.png') no-repeat;
  background-size: 100% 100%;

  img {
    width: 100%;
    height: 100%;
  }

  .user {
    width: 85%;
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 1001;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      .img {
        height: 70px;
      }
    }


    .top {
      color: #333;
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: right;
    }

    .btm {
      display: flex;
      // align-items: flex-end;
      align-items: center;
      margin-top: 25px;

      .searchAll {
        width: 250px;
        height: 30px;
        border-radius: 15px;
        background: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, .2);
        margin-right: 26px;

        .bannervideo_bottom {
          display: flex;
          margin: 0 auto;
          width: 100%;
          height: 0;
          top: 56px;
          left: 330px;

          input {
            box-sizing: border-box;
            color: rgba(0, 0, 0, .65);
            font-size: inherit;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
            line-height: inherit;
            width: calc(100% - 40px);
            height: 36px;
            border-radius: 19px 0 0 19px;
            padding-left: 20px;
            border: none;
            outline: none;
          }

          div {
            width: 40px;
            height: 36px;
            background-color: #fff;
            cursor: pointer;
            color: #000;
            font-size: 16px;
            float: right;
            text-align: center;
            line-height: 36px;
            align-items: center;
            border-radius: 0 19px 19px 0;
            font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;

            i {
              margin-right: 15px;
            }
          }

        }
      }

      li {
        cursor: pointer;
        user-select: none;
      }

      .login {
        width: 104px;
        height: 30px;
        border-radius: 15px;
        text-align: center;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
        color: #888;
        font-family: Source Han Sans CN;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        cursor: pointer;
        user-select: none;
      }

      .login-info {
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        z-index: 1999;

        img {
          width: 50px;
          height: 50px;
          border-radius: 100%;
        }

        .user-center {
          margin-left: 9px;

          .u-center {
            color: #1f5571;
            font-family: Source Han Sans CN;
            font-size: 16px;
            font-style: normal;
            font-weight: 550;
            line-height: normal;
            margin-bottom: 4px;
          }

          .hy {
            color: #666;
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: normal;
            max-width: 136px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }


  }

  .Fixed {
    position: fixed;
    top: 156px;
    right: 121px;
    width: 103px;
    height: 99px;
    z-index: 1000;
    user-select: none;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.login-info-box{
  position: relative;
}
.login-info-box:hover .dropdownCard{
  display: block!important;
}

.dropdownCard {
  display: none;
  width: 230px;
  height: 310px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  padding: 0 16px;
  position: absolute;
  top: 40px;
  z-index: 1000;

  .tag {
    width: 0;
    margin-top: -6px;
    border-bottom: 8px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 0;
  }

  .name {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(221, 221, 221, 0.50);
    color: #000;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;

    img {
      width: 24px;
      height: 24px;
    }

  }

  .item {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.50);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }

  .btn {
    width: 100%;
    height: 36px;
    text-align: center;
    border: 1px solid #999;
    background: #FFF;
    color: #333;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 36px;
    margin: 30px 0;

  }
}

.theme1 {
  .title {
    .userType {
      color: #fff;

    }

    .userName {
      color: #fff;

    }
  }
}

.confirmDivClass {
  border-radius: 4px;
}

.confirmDivClass .el-message-box__message {
  padding-left: 0;
  padding-right: 0;
}
</style>
