<template>
  <el-upload class="idPic-uploader" accept="image/*" :action="uploadImgUrl" :show-file-list="false"
    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="avatarfile">
    <img v-if="imageUrl" :src="imageUrl" class="avatar">
    <div v-else class="avatar-uploader-icon flexC">
      <i class="el-icon-camera-solid "></i>
      <p>{{text}}</p>
  </div>

  </el-upload>
</template>
<script>
  export default {
    data() {
      return {
        imageUrl: '',
        baseUrl: process.env.VUE_APP_BASE_API,
        uploadImgUrl: process.env.VUE_APP_BASE_API + "/artuserinfo/avatar", // 上传的图片服务器地址
        headers: {
          // Authorization: "Bearer " + getToken(),
        },
      };
    },
    props: {
      defaultImg: {
        type: String,
        default: null
      },
      text: {
        type: String,
        default: "上传人像面"
      },
    },
    model: {
      prop: 'defaultImg',
      event: 'input'
    },
    watch: {
      defaultImg(newValue, oldValue) {
        this.imageUrl = newValue
      }
    },
    created() {
      if (this.defaultImg) {
        this.imageUrl = this.defaultImg
      }
    },
    methods: {
      handleAvatarSuccess(res, file) {
        if (res.code == 200) {
          this.imageUrl = res.imgUrl
          this.$emit('input', this.imageUrl)
        } else {
          this.$message.error(res.msg ? res.msg : '图片上传失败');
        }
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isLt2M;
      }
    }
  }
</script>
<style >
  .idPic-uploader {
    width: 322px;
  height: 198px;
    /*// line-height: 198px;*/
    text-align: center;
    /* border-radius: 50%; */
    overflow: hidden;
    /* background-color: rgba(0,0,0,0.1); */
  }

  .idPic-uploader  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
     height: 196px;
  }

  .idPic-uploader  .el-upload:hover {
    border-color: #d9d9d9;
  }

  /*.avatar-uploader-icon {*/
  /*  color: #E8E8E9;*/
  /*  width: 320px;*/
  /*  height: 196px;*/
  /*  text-align: center;*/

  /*  !* border-radius: 50%; *!*/
  /*}*/
.avatar-uploader-icon i{
   font-size: 64px;
}
.avatar-uploader-icon  p {
      margin-top: 10px;
      width: 100%;
      height: 28px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height */
      color: #666666;
    }
  .avatar {
    width: 320px;
    height: 196px;
    /* border-radius: 50%; */
    display: block;
  }
</style>
