<template>
  <div>
    <topNav>
      <div class="sub-nav-bar flex-row align-center">
        <img class="sub-nav-icon" src="../assets/img-new/icon-home.png" />
        <span class="sub-nav-item active">> 求职招聘 ></span>
        <router-link class="sub-nav-item active" to="/home"> 招聘考试 ></router-link>
        <span class="sub-nav-item">个人中心</span>
      </div>
    </topNav>
    <div class="divBox">
      <mainNav></mainNav>
      <div class="elrowBox">
        <div class="mine-content flex justify_between align-start">
          <mineMenu></mineMenu>

          <div class="miContent flex-1">
            <div class="miCtitle">账号信息</div>
            <div class="miCbody">
              <ul>
                <li class="flex miCbodyinfoLI ">
                  <p class="miCbodyName">头像</p>
                  <p class="miCbodycon flex-1"><img src="../assets/img-new/avatar.png" alt=""></p>
                  <!-- <p class="miCbodyBtn" @click="handleheadPic"> <i class="el-icon-user"></i> 上传头像</p> -->
                </li>
                <li class="flex miCbodyinfoLI align-start">
                  <p class="miCbodyName">用户名</p>
                  <p class="miCbodycon flex-1">{{userInfo|showName}}</p>
                  <!-- <p class="miCbodyBtn" style=""></p> -->
                </li>
                <li class="flex miCbodyinfoLI align-start">
                  <p class="miCbodyName">手机号</p>
                  <p class="miCbodycon flex-1">{{userInfo&&userInfo.user ? userInfo.user.phone:''}}</p>
                  <!-- <p class="miCbodyBtn" @click="phoneNumberVisible=true"> <i class="el-icon-edit"></i> 更换</p> -->
                </li>
                <!-- <li class="flex miCbodyinfoLI align-start">
                  <p class="miCbodyName">登录密码</p>
                  <p class="miCbodycon flex-1">密码要求至少包含字母，符号或数字中的两项且长度超过8位建议经常修改密码，以保账号更加安全</p>
                  <p class="miCbodyBtn" @click="changeVisible=true"><i class="el-icon-edit"></i> 更换</p>
                </li> -->
               <!-- <li class="flex miCbodyinfoLI align-start">
                  <p class="miCbodyName">实名认证</p>
                  <p class="miCbodycon flex-1 text-danger">未认证</p>
                  <p class="miCbodyBtn" @click="$router.push('/identification')"><i class="el-icon-edit-outline"></i> 去认证</p>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerBox></footerBox>
    <div class="logindialogBox">
      <el-dialog width="400px" :visible.sync="changeVisible" :close-on-click-modal="false" center @close='closeforget'>
        <div class=" loginInput flex justify_center">
          <i class="el-icon-close reel-icon" @click="closeforget"></i>
          <div class="register-form">
            <h3>更换密码</h3>
            <div>
              <el-form :model="changeForm" :rules="forgotRules" ref="changeForm" class="demo-ruleForm">
                <el-form-item prop="nowPass">
                  <el-input v-model="changeForm.nowPass" placeholder="现密码" style="position: relative;"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input v-model="changeForm.password" show-password placeholder="新密码"></el-input>
                </el-form-item>
                <el-form-item prop="repassword">
                  <el-input v-model="changeForm.repassword" show-password placeholder="确认新密码"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="success" @click="submitchangeForm('changeForm')" class="registerBtn">确认更换</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- 更换手机号 -->
      <el-dialog width="400px" :visible.sync="phoneNumberVisible" :close-on-click-modal="false" center
        @close='closephoneNumber'>
        <div class=" loginInput flex justify_center">
          <i class="el-icon-close reel-icon" @click="closephoneNumber"></i>
          <div class="register-form">
            <h3>更换手机号</h3>
            <div>
              <el-form :model="phoneNumberForm" :rules="forgotRules" ref="phoneNumberForm" class="demo-ruleForm">
                <el-form-item prop="nowPass">
                  <el-input v-model="phoneNumberForm.phoneNumber" placeholder="原手机号" style="position: relative;">
                  </el-input>
                </el-form-item>
                <el-form-item prop="passid">
                  <div class="flex justify_between">
                    <el-input v-model="phoneNumberForm.verificationCode" placeholder="验证码"></el-input>
                    <span class="getCode" @click="handlegetCode">{{codeStr}}</span>
                  </div>
                </el-form-item>
                <el-form-item prop="repassword">
                  <el-input v-model="phoneNumberForm.newphoneNumber" show-password placeholder="新手机号"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="success" @click="submitphoneNumber('phoneNumberForm')" class="registerBtn">确认更换
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 上传头像 -->
      <el-dialog width="400px" :visible.sync="uploadVisible" :close-on-click-modal="false" center
        @close='closeupload'>
        <div class=" loginInput flex justify_center">
          <i class="el-icon-close reel-icon" @click="closeupload"></i>
          <div class="register-form">
            <h3>{{headPicNmae}}</h3>
            <div class="flexC ">
             <uoloadHead v-model="avatar"></uoloadHead>
            </div>

            <el-button style="margin-top: 30px;" type="success" @click="submitphoneNumber('phoneNumberForm')" class="registerBtn ">确认
            </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import {
    getToken,removeToken
  } from '@/utils/auth'
  import {
    getInfo
  } from "@/api/login.js"
  import mainNav from '@/components/mainNav.vue'

  var timer = null
  export default {
    name: 'home',
    components: {
      mainNav
    },
    data() {
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.forgotForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        newList: [{
            name: '人力资源社会保障部办公厅关于进一步做好职称评审工作的通知',
            time: '2023-01-03'
          },
          {
            name: '收好这张图！享受个人养老金税收优惠政策三步走',
            time: '2023-01-03'
          },
          {
            name: '把优秀人才集聚到党和人民事业中来（新论）',
            time: '2023-01-03'
          },
          {
            name: '开始确认，可以退钱了',
            time: '2023-01-03'
          },
          {
            name: '收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走',
            time: '2023-01-03'
          },
          {
            name: '可获6万元资助！2022年“武汉英才”计划培育支持专项科技创新领域申报启动',
            time: '2023-01-03'
          },
        ],
        currentPage1: 5,
        currentPage2: 5,
        currentPage3: 5,
        currentPage4: 1,
        total: 5,
        pageSize: 10,
        titleOne: 1,
        changeVisible: false,
        phoneNumberVisible: false,
        uploadVisible:false,
        changeForm: {
          password: '',
          repassword: '',
          nowPass: ''
        },
        phoneNumberForm: {
          phoneNumber: '',
          verificationCode: '',
          newphoneNumber: ''
        },

        forgotRules: {
          nowPass: [{
            required: true,
            message: '请输入现密码',
            trigger: 'blur'
          }],

          password: [{
            required: true,
            message: '请输入新密码',
            trigger: 'blur'
          }],
          repassword: [{
              required: true,
              message: '请再次确认密码',
              trigger: 'blur'
            },
            {
              validator: validatePass2,
              trigger: 'blur'
            }
          ],
        },
        checked: false,
        codeStr: '获取验证码',
        isSend: true,
        timeNum: 60,
        headPicNmae:"上传头像",
        avatar:'',
        userInfo:{}
      }
    },
    created() {
      this.loginOnto()
    },
    methods: {
      loginOnto() {
        if (getToken()) {
          this.loginOn=true
          getInfo().then(res => {
              if(res.code==200){
                this.userInfo =res.data;
                this.$store.state.haveLogin =true;
              }else{
                this.$store.state.haveLogin =false;
              }
          }).catch(e=>{
            this.$store.state.haveLogin =false;
          })
        } else {
          this.$store.state.haveLogin =false;
        }

      },
      handleheadPic(){
        this.uploadVisible=true
      },
      closeupload(){
        this.uploadVisible=false
      },
      submitphoneNumber() {

      },
      closephoneNumber() {
        this.phoneNumberForm = {
          phoneNumber: '',
          verificationCode: '',
          newphoneNumber: ''
        }
        this.$refs.phoneNumberForm.resetFields()
        this.phoneNumberVisible = false;
      },
      handlegetCode() {
        if (this.isSend) {
          if (this.ruleForm.phonenumber) {
            var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
            if (reg_tel.test(this.ruleForm.phonenumber)) {

              getCode(this.ruleForm.phonenumber).then(res => {
                this.timerOn()
                this.$message.success(res.msg);
              })
            } else {
              this.$message.error('请输入正确的手机号');
            }

          } else {
            this.$message.error('请输入手机号');
          }
        }
      },
      timerOn() {
        let _this = this
        _this.isSend = false
        timer = setInterval(() => {
          console.log(_this.codeStr)
          if (_this.timeNum == 0) {
            _this.closeTimeer()
          } else {
            _this.timeNum -= 1
            _this.codeStr = _this.timeNum + "秒后重试"
          }
        }, 1000)
      },
      closeTimeer() {
        this.timeNum = 60;
        this.codeStr = "获取验证码";
        this.isSend = true;
        clearInterval(timer)
      },
      chose(e) {
        this.titleOne = e
      },

      closeforget() {
        this.changeForm = {
          password: '',
          repassword: '',
          nowPass: ''
        }
        this.$refs.changeForm.resetFields()
        this.changeVisible = false;
      },
      submitchangeForm() {

      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
    }
  }
</script>

<style lang="less" scoped>
  .recruitBtn {
    margin-bottom: 38px;

    li {
      width: 19%;
      padding-top: 9%;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }


  .incontentRight {
    margin-bottom: 48px;
    background: #FFFFFF;
    margin-top: 27px;

    .incRightTitle {
      height: 48px;
      padding: 0 8px;

      li {
        line-height: 48px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 48px;
        color: #333333;
        padding: 0 16px;
      }

      .activeLi {
        color: #AD9C72;

      }
    }

  }

  .incRightCon {
    padding: 16px;
  }

  .incRightCon .incRightConHot {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #FDF7ED;
    border-radius: 10px;

    p {
      &:first-child {
        width: 52px;
        flex-shrink: 0;
        text-align: center;
        // padding: 0 10px;
        height: 29px;
        background: linear-gradient(90deg, #F6A14B -1.91%, #F27E0B 100%);
        border-radius: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #FFFFFF;
        margin: 0 10px;
      }

      &:last-child {
        flex: 1;
        flex-shrink: 0;
        width: calc(100% - 60px);
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: #333333;
      }
    }
  }

  .incRightConUl {
    min-height: 480px;

    li {

      line-height: 48px;
      height: 48px;

      i {
        font-size: 26px;
        color: #AD9C72;
      }

      div {
        overflow: hidden;
        // max-width: 80%;
      }

      .content {
        width: 85%;
        color: #333333;
      }

      p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

      }

      .time {
        width: 100px;
        text-align: right;
        color: #999999;
      }

      .status {
        width: 72px;
        height: 24px;
        background: #FDF7ED;
        border-radius: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FF5202;
        text-align: center;
      }
    }

  }

  .miContent {
    background: #FFFFFF;
  }

  .miCtitle {
    padding: 20px 37px;
    position: relative;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    border-bottom: 1px solid #DFDFDF;

    &::before {
      content: '';
      width: 4px;
      height: 20px;
      position: absolute;
      top: 20px;
      left: 25px;
      background: #2C3C6F;
    }
  }

  .miCbody {
    padding: 30px 40px;
    min-height: 529px;
  }

  .miCbodyinfoLI {
    padding: 6px 0;
  }

  .miCbodyName {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: right;
    color: #666666;
    width: 60px;
    margin-right: 60px;
  }

  .miCbodycon {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-top: 5px;
    color: #333333;
    margin-right: 30%;

    img {
      width: 46px;
      height: 46px;
      object-fit: cover
    }
  }

  .miCbodyBtn {
    width: 90px;
    height: 32px;
    background: #FDF7ED;
    border-radius: 2px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #AD9C72;
    text-align: center;
    cursor: pointer;
  }


  .loginInput {
    width: 100%;
    padding: 56px 0 36px;
  }

  .loginInput h3 {
    text-align: center;
    font-size: 32px;
    color: #333;
    margin-bottom: 30px;

    img {
      height: 100%;
    }
  }



  .loginInput .el-form {
    width: 100%;
  }

  .registerBtn {
    width: 100%;
    height: 46px;
    background: #C8B484;
    // border-radius: 61px 61px 61px 61px !important;;
    border-color: #C8B484;
    font-size: 16px;

    &:hover {
      background: #C8B484;
      // border-radius: 61px 61px 61px 61px !important;;
      border-color: #C8B484;
    }
  }

  .gotoLogin {
    width: 47px;
    height: 18px;
    font-size: 14px;
    font-family: OPPOSans-Regular, OPPOSans;
    font-weight: 400;
    color: #8E8C8C;
    line-height: 16px;

    span {
      cursor: pointer;
      color: #000;
    }
  }


  .register-form {
    width: 80%;
  }

  @media screen and (max-width:576px) {
    .logindialogBox {
      .el-dialog {
        width: 90% !important;
        max-width: 400px;
      }

      .register-form {
        width: 80%;
      }

      .reel-icon {
        right: 10px;
        top: 10px;
        font-size: 20px;
        color: #8E8C8C;
      }

      .loginInput {
        width: 100%;
        height: 480px;
      }
    }
  }


  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #606266;
  }

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #C8B484;
    border-color: #C8B484;
  }

  .registerBox {
    position: relative;
  }

  .reel-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #B8BDCD;
    font-size: 24px;
  }

  .logindialogBox /deep/ .el-dialog__header {
    display: none !important;
  }

  .logindialogBox /deep/ .el-dialog__body {
    padding: 0 !important;
  }

  .getCode {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    width: 34%;
    border-radius: 4px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    /* identical to box height */
    color: #AD9C72;

    &::before {
      content: "";
      position: absolute;
      top: 13px;
      left: 0px;
      width: 1px;
      height: 16px;
      background-color: #2C3C6F;
    }
  }
  .mine-content{
    margin-top: 60px;
  }
</style>
