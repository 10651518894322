<template>
  <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
          <div>
            <h1 class="titleInfo">{{recruitFrom.recruitmentTitle}}</h1>
            <v-form-render  ref="vFormRef" :global-dsv="globalDsv">
            </v-form-render>
          </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import {applyPost ,getRecruitmentInfo ,getApplyInfo ,updateApplyInfo,getConfig} from '@/api/login.js'
export default {
  name: "registrationForm",
  data(){
    return{
      dialogVisible:false,


    }
  },
  methods:{

    getData(){
      getRecruitmentInfo(this.recruitmentId ,this.zpNo).then(res=>{
        if(res.code==200){
          this.recruitment =res.data
          this.recruitmentId = this.recruitment.id;
          this.postList =res.data.recruitPosts;
          let deptList =null
          res.data.recruitPosts.forEach(item=>{
            if(item.deptId){
              if(!deptList){
                deptList= {}
              }
              deptList[item.deptId] = {
                deptId:item.deptId,
                deptName:item.deptName
              }
            }
          })
          this.deptList =deptList;
          this.getFormInfo(res.data.fromId)
        }
      })
    },
    getFormInfo(fromId){
      getConfig(fromId).then(res=>{
        let formJson=JSON.parse(res.data.fromJson)
        // formJson.
        console.log(formJson)
        this.$refs.vFormRef.setFormJson(formJson)
        //设置表单数据

        if(this.$route.query.applyId || this.$route.query.oldId){
          this.oId = this.$route.query.oldId
          this.applyId= this.$route.query.applyId;
          this.getApplyData();
        }
        // this.$refs.vFormRef.setFormData(formJson)

      })
    },
    getApplyData(){
      let appyId =this.oId ? this.oId :this.applyId;
      getApplyInfo(appyId).then(res=>{

        if(res.data.code==200){
          this.formId=res.data.data.id
          if(res.data.data.resultJson){
            let resultJson=res.data.data.resultJson

            setTimeout(()=>{
              this.$refs.vFormRef.setFormData( JSON.parse(resultJson))
            },200)


            // this.$refs.vFormRef.setFormData(formJson)
          }

          // res.data =res.data.data;


        }
      })
    },
    handleClose(){
      this.dialogVisible=false
    }
  }

}
</script>

<style scoped lang="less">
.titleInfo{
  font-size: 24px;
  text-align: center;
//font-weight: bold;
}
</style>
