<template>
  <div class="logindialogBox">
    <el-dialog title="提示" :visible.sync="dialogVisible" :close-on-click-modal="false" width="500px" center  @close="closeRegist">
      <div class="flex registerBox">
        <i class="el-icon-close el-icon" @click="closeMe"></i>

        <div class=" registerInput flex justify_center">
          <div class="register-form">
            <h3>新用户注册</h3>
            <ul class="loginType flex justify_center">
              <!-- <div class="loginTBorder" :style="{left: ltIndex==1?'87px':'228px'}"></div>
              <li :class="ltIndex==1?'activeLi':''" @click="choseLt(1)">
                个人用户
              </li>
              <li :class="ltIndex==2?'activeLi':''" @click="choseLt(2)">
                企业用户
              </li> -->
            </ul>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
              <template v-if="ltIndex==1">
              <el-form-item prop="phone">
                <el-input v-model="ruleForm.phone" placeholder="手机号" ></el-input>

              </el-form-item>
              <el-form-item prop="code" style="position: relative;">
                <el-input v-model="ruleForm.code" placeholder="验证码"></el-input>
                 <span class="getCode" @click="handlegetCode">{{codeStr}}</span>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="ruleForm.password " show-password placeholder="密码"></el-input>
              </el-form-item>
              <el-form-item prop="repassword">
                <el-input v-model="ruleForm.repassword " show-password placeholder="确认密码"></el-input>
              </el-form-item>
              </template>
              <template v-if="ltIndex==2">
              <el-form-item prop="phonenumber">
                <el-input v-model="ruleForm.phonenumber" placeholder="请输入企业全称" ></el-input>
              </el-form-item>
              <el-form-item prop="phonenumber">
                <el-input v-model="ruleForm.phonenumber" placeholder="请输入企业账号" ></el-input>
              </el-form-item>
              <el-form-item prop="phonenumber">
                <el-input v-model="ruleForm.phonenumber" placeholder="请输入手机号码" ></el-input>
              </el-form-item>
              <el-form-item prop="yzm" style="position: relative;">
                <el-input v-model="ruleForm.yzm" placeholder="验证码"></el-input>
                 <span class="getCode" @click="handlegetCode">{{codeStr}}</span>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="ruleForm.password " show-password placeholder="请输入密码"></el-input>
              </el-form-item>
              <el-form-item prop="repassword">
                <el-input v-model="ruleForm.repassword " show-password placeholder="确认密码"></el-input>
              </el-form-item>
              </template>
              <el-form-item>
                <el-button type="success" @click="submitForm('ruleForm')" class="registerBtn">立即注册</el-button>
              </el-form-item>

            </el-form>
            <!-- <div>
              <el-checkbox v-model="checked">
                <div class="gotoLogin" style="font-size: 12px;">我已阅读并接受
                  <router-link style="color:#C8B484 ;" class="hover-color" :to="{path:'article',query:{type:'condition'}}"  target="_blank"> 《用户注册协议》 </router-link>
                </div>
              </el-checkbox>
            </div> -->
            <div class="gotoLogin margin-top-20" style="text-align: center;width: 100%;">已有账号？ <span style="color:#F27E0B ;" class="hover-color"
                @click="gotoLogin">立即登录</span> </div>
          </div>
        </div>
      </div>

    </el-dialog>


  </div>

</template>

<script>
  import {
    WOW
  } from 'wowjs'
  import {
    sendVcode,
    register
  } from "@/api/login.js"
  import {
    checkMobile
  } from '@/utils/request'
  var timer=null
  export default {
    name: 'home',
    components: {

    },
    data() {
      var phone = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入手机号'));
        } else {
          var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
          if (!reg_tel.test(value)) {
            callback(new Error('请输入正确的手机号'));
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        Sindex: 0,
        searchone: 1,
        dialogVisible: false,
        forgetVisible: false,
        ruleForm: {
          phone: '',
          code: '',
          password: '',
          repassword: '',
        },
        rules: {
          phone: [{
            validator: phone,
            trigger: 'blur'
          }],
          code: [{
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }],
          password: [{
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 12,
              message: '长度在 3 到 12 个字符',
              trigger: 'blur'
            }
          ],
          repassword: [{
            validator: validatePass2,
            trigger: 'blur'
          }],

        },
        checked: false,
        codeStr: '获取验证码',
        isSend: true,
        timeNum:60,
        ltIndex:1,

      }
    },

    mounted() {
      new WOW().init()
    },
    methods: {
      choseLt(e) {
        // this.closeTimeer()
        this.ltIndex = e
        this.$refs.ruleForm.clearValidate();
      },
      closeRegist(){
        this.ruleForm={
          phone: '',
          code: '',
          password: '',
          repassword: '',
        }
        this.$refs.ruleForm.resetFields()

          this.dialogVisible=false
      },
      // 获取验证码
      handlegetCode() {
        if (this.isSend) {
          if (this.ruleForm.phone) {
            var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
            if (reg_tel.test(this.ruleForm.phone)) {
              let formData={
                phone :this.ruleForm.phone,
                type:2
              }
              sendVcode(formData).then(res => {
                if(res.code==200){
                  this.$message.success('验证码发送成功');
                  this.timerOn()
                }else{
                  this.$message.error(res.msg ?res.msg: '验证码发送失败');
                }

              })
            } else {
              this.$message.error('请输入正确的手机号');
            }

          } else {
            this.$message.error('请输入手机号');
          }
        }
      },
      // 倒计时
     timerOn() {
       let _this = this
       _this.isSend = false
       timer = setInterval(function() {
         console.log(_this.codeStr)
         if (_this.timeNum < 1) {
            _this.timeNum=60
           _this.codeStr = "获取验证码"
           _this.isSend = true
           clearInterval(timer)
         } else {
           _this.timeNum -= 1
            _this.codeStr = _this.timeNum+"(秒)后重试"
         }
       }, 1000)
     },
      handleClose() {

      },
      gotoLogin() {
        this.dialogVisible = false;
        this.$emit('login')
      },
      // 注册
      submitForm(formName) {
        // if (!this.checked) return this.$message.error('请勾选同意协议');
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.submitLoading){
              return;
            }
            this.submitLoading=true;
            register(this.ruleForm).then(res => {
               this.submitLoading=false;
              if(res.code==200){
                this.$message.success('注册成功');
                setTimeout(() => {
                  this.gotoLogin()
                }, 1500)
              }else{
                this.$message.error(res.msg ? res.msg :'注册失败');
              }
            }).catch(e=>{
              this.submitLoading=false;
            })
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      closeMe() {
        this.dialogVisible = false
      },
      open() {
        this.dialogVisible = true
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #606266;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #C8B484;
      border-color: #C8B484;
  }
  .logindialogBox /deep/ .el-dialog__header {
    display: none !important;
  }

  .logindialogBox /deep/ .el-dialog__body {
    padding: 0 !important;
  }

  .registerBox .registerImg {
    width: 606px;
    height: 520px;
    img{
      height: 100%;
    }
  }

  .registerBox {
    position: relative;
  }

  .registerBox .el-icon {
   cursor: pointer;
   position: absolute;
   top: 10px;
   right: 10px;
   color: #B8BDCD;
   font-size: 24px;
  }

  .registerInput {
    width: 100%;
    background-color: #fff;
    padding: 30px 0;
  }

  .getCode {
    position: absolute;
    right: 0;
    top:0;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    width: 34%;
    border-radius: 4px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    /* identical to box height */
    color: #AD9C72;
    &::before{
      content: "";
      position: absolute;
      top: 13px;
      left: 0px;
      width:1px;
      height: 16px;
      background-color: #AD9C72;
    }
  }

  .registerInput h3 {
    text-align: center;
    height: 34px;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .registerInput .el-input__inner {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
  }

  .registerInput .el-form {
    width: 100%;
  }

  .registerBtn {
   width: 100%;
   height: 46px;
   background: #C8B484;
   // border-radius: 61px 61px 61px 61px !important;;
   border-color: #C8B484;
   font-size: 16px;
   &:hover {
     background: #C8B484;
     // border-radius: 61px 61px 61px 61px !important;;
     border-color: #C8B484;
   }
  }

  .gotoLogin {
    width: 47px;
    height: 18px;
    font-size: 14px;
    font-family: OPPOSans-Regular, OPPOSans;
    font-weight: 400;
    color: #8E8C8C;
    line-height: 16px;

    span {
      cursor: pointer;
      color: #000;
    }
  }
  .register-form{
    width: 80%;
  }
  @media screen and (max-width:992px){
    .registerImg{
      display: none;
    }
    .logindialogBox{
      .el-dialog {
        width: 400px !important;
      }
    }
  }
  @media screen and (max-width:576px){
    .logindialogBox{
      .el-dialog {
        width: 90% !important;
        max-width: 400px;
      }
      .register-form{
        width: 80%;
      }
      .registerBox .el-icon{
        right: 10px;
        top: 10px;
        font-size: 20px;
        color: #8E8C8C;
      }
      .registerInput{
        width: 100%;
        height: 500px;
      }
    }
  }


  .loginType {
    // border-bottom: 1px solid #E2E2E2;
    position: relative;
    margin-bottom: 30px;

    .loginTBorder {
      position: absolute;
      bottom: 0;
      left: 87px;
      width: 90px;
      height: 4px;
      background-color: #C8B484;
      transition: 0.5s;
      border-radius: 20px;
    }

    li {
      text-align: center;
      // width: 50%;
      height:66px;
      font-size: 20px;
      font-family: OPPOSans-Regular, OPPOSans;
      font-weight: 600;
      color: #666666;
      line-height: 66px;
      cursor: pointer;
      margin: 0 30px;
    }

    .activeLi {
      position: relative;
      color: #C8B484;

    }
  }
</style>
