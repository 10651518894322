<template>
  <div>
    <topNav>
      <div class="sub-nav-bar flex-row align-center">
        <img class="sub-nav-icon" src="../assets/img-new/icon-home.png" />
        <span class="sub-nav-item active" @click="goHome">> 求职招聘 ></span>
        <router-link class="sub-nav-item active" to="/home"> 招聘考试 ></router-link>
        <span class="sub-nav-item">事业单位招聘</span>
      </div>
    </topNav>

    <div class="divBox">

      <mainNav></mainNav>
      <div class="main-title">
        <img class="title-icon" src="../assets/img-new/title-left.png" />
        <span class="title-text">事业单位招聘</span>
        <img class="title-icon" src="../assets/img-new/title-right.png" />
      </div>

      <div class="elrowBox">
        <div class="home-search">
          <div class="search-content">
            <div class="search-group flex-row align-center">
              <el-input v-model="keyword" class="search-input"></el-input>
              <el-button class="search-btn">
                <div class="flex-row align-center" @click="getList">
                  <img src="../assets/img-new/icon-search-2.png" />
                  <span style="btn-search-text">搜索</span>
                </div>
              </el-button>
            </div>

            <div class="search-filter flex-row flex-row justify-between">
              <div class="flex-row align-center">
                <span class="text-lg">申报状态：</span>
                <div class="flex-row align-center filter-list">
                  <span class="filter-item" :class="{active:status==0}" @click="selectStatus(0)">全部</span>
                  <span class="filter-item" :class="{active:status==1}" @click="selectStatus(1)">报名中</span>
                  <span class="filter-item" :class="{active:status==2}" @click="selectStatus(2)">已结束</span>
                </div>
              </div>
              <div class="flex-row align-center sort-list">
                <div class="sort-item" :class="{active:sort=='time'}" @click="selectSort('time')">
                  <span>发布时间</span>
                  <div class="sort-icon"></div>
                </div>
                <div class="sort-item" :class="{active:sort=='default'}" @click="selectSort('default')">
                  <span>综合排序</span>
                  <div class="sort-icon"></div>
                </div>
                <div class="sort-item" style="cursor: auto;">
                  <span>公告数量:</span>
                  <div class="num">{{newList.length}}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="elrowBox" style="min-height: 60vh;">
        <div class="recruit-list">
          <div class="recruit-item flex-row align-center" v-for="(item,index) in newList" :key="index">
            <div class="flex-1">
              <div class="title curser-pointer"  @click="gotoDetail(item)">
                <div v-if="item.isEnter==0" class="icon-end">
                  <span v-if="item.status==3 || item.status==4">已结束</span>
                  <span v-else>未开始</span>
                </div>
                <div v-else class="icon-active">进行中</div>
                <div class="line-1 flex-1">{{item.title}}</div>
              </div>
              <div class="info margin-top-10">
                <p>报名时间：{{item.applyStartTime}} ～ {{item.applyEndTime}}</p>
                <p class="margin-top-20 flex-row align-center"><img class="icon-unit" src="../assets/img-new/icon-unit.png">{{item.recruitUnit}}</p>
              </div>

            </div>

            <div v-if="item.isEnter==0">
              <div v-if="item.status==3 || item.status==4" class="text-center margin-bottom-20">报名截止</div>
              <div v-else class="text-center margin-bottom-20">未开始</div>
              <el-button size="small" class="stop" @click="gotoDetail(item)">查看详情 ></el-button>
            </div>
            <div v-else>
              <div class="text-center margin-bottom-20 text-primary">进行中</div>
              <el-button size="small" type="success" @click="gotoDetail(item)">查看详情 ></el-button>
            </div>
          </div>

        </div>


      </div>


    </div>

    <footerBox></footerBox>
  </div>
</template>

<script>
  import mainNav from '@/components/mainNav.vue'
  import{getRecruitmentList ,getRecruitmentTopic} from '@/api/login.js';
  export default {
    name: 'home',
    components: {
      mainNav
    },
    data() {
      return {
        keyword:null,
        newList:[],
        allList:[],
        status:0,
        sort:'time',
      }
    },

    created() {
      this.getData();
    },
    methods:{
      selectStatus(status){
        this.status=status;
        this.getNews();
      },
      selectSort(sort){
        this.sort =sort
        this.getList();
      },
      getNews(){
        if(this.status==1){
          this.newList = this.allList.filter(item=>{
            return item.isEnter==1;
          })
        }
        else if(this.status==2){
          this.newList = this.allList.filter(item=>{
            return (item.status==3 || item.status==4) && item.isEnter==0
          })
        }else{
          this.newList =  [...this.allList]
        }
      },
      getData(){
        this.getList();
      },
      getList(){
        let query={
          sort:this.sort
        }
        if(this.topicId){
          query.topicId= this.topicId
        }
        if(this.keyword){
          query.keyword = this.keyword;
        }
        query.isPc=0;
        getRecruitmentList(query).then(res=>{
          if(res.rows){
            this.allList = res.rows;
            this.getNews();
          }
        })
      },
      gotoDetail(e){
        let query={
          id:e.id
        }
        if(e.zpNo){
          query={
            no:e.zpNo
          }
        }
        this.$router.push({path:'/detail',query})
      },

      handleSizeChange(val) {
              console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
              console.log(`当前页: ${val}`);
            },
      goHome(){
        window.location.href=process.env.VUE_APP_BASE_SSO+"/second/JobHire";
      }
    }
  }
</script>

<style lang="less" scoped>
  .home-search{
    margin-top: 70px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    .search-content{
      padding: 30px 50px;
    }
    .search-group{
      background: #FF6C2B;
       border-radius: 4px;
       overflow: hidden;
    }
    .search-input{
      height: 47px;
      background:#FFFFFF;
      border-radius: 4px 0 0 4px;
      margin-left: 1px;
      /deep/ .el-input__inner{
        height: 100%;
        width: 100%;
        border: none;
        font-size: 16px;
      }
    }
    .search-btn{
      height: 50px;
      background: #FF6C2B;
      border: none;
      border-radius: 0px 4px 4px 0px;
      color: #FFFFFF;
      padding:0 20px;
      img{
        width: 28px;
        height: 28px;
      }
    }
    .search-filter{
      margin-top: 50px;
      border-top:  1px solid #F2F2F2;
      padding-top: 20px;

      .filter-list{
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        color: #000000;
        .filter-item{
          padding: 2px 15px;
          cursor: pointer;
          &.active{
            color: #FFFFFF;
            background-color: #FF6C2B;
            border-radius: 2px;
          }
        }
      }
      .sort-list{
        .sort-item{
          cursor: pointer;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          color: #666666;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 20px;
          .sort-icon{
            width: 16px;
            height: 16px;
            background-image: url(../assets/img-new/icon-down.png);
            background-size: 100% 100%;
          }
          &.active{
            .sort-icon{
              background-image: url(../assets/img-new/icon-down-active.png);
            }
          }
          .num{
            color:#FF6C2B;
            padding-left: 6px;
          }
        }
      }
    }
  }

  .recruit-list{
    margin-top: 33px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    .recruit-item{
      padding: 25px 20px;
      border-top: 1px solid #F2F2F2;
      margin: 0 20px;
      &:first-child{
        border-top:none;
      }

      .title{
        font-size: 18px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon-end{
          background-image: url(../assets/img-new/icon-end.png);
          background-size: 100% 100%;
          width: 48px;
          height: 28px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          color: #FFFFFF;
          padding-left: 8px;
          margin-right: 10px;
        }
        .icon-active{
          background-image: url(../assets/img-new/icon-active.png);
          background-size: 100% 100%;
          width: 48px;
          height: 28px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          color: #FFFFFF;
          padding-left: 8px;
          margin-right: 10px;
        }
      }
      .info{
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        .icon-unit{
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .btn-search-text{
    font-size: 16px;
    margin-left: 12px;
  }
</style>
