
import CryptoJS from 'crypto-js/crypto-js'

// 默认的 KEY 与 iv 如果没有给
const KEY = CryptoJS.enc.Utf8.parse("ec4c07568c8d4101");//秘钥
const IV = CryptoJS.enc.Utf8.parse('ec4c07568c8d4101');//偏移量

// const sm2 = require('sm-crypto').sm2
// const privateKey='00e2f8c8dd29901588852c3858a4e55f3a76f538cf5f1791f3dcc49a576b82e464'

const TokenKey = 'userToken'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  localStorage.removeItem('ssoToken');
  return  localStorage.removeItem(TokenKey)
}

// /**
//  \* AES加密 ：字符串 key iv 返回base64
//  */
// export function Encrypt(word, keyStr, ivStr) {
//   let key = KEY;
//   let iv = IV;
//   if (keyStr) {
//     key = CryptoJS.enc.Utf8.parse(keyStr);
//     iv = CryptoJS.enc.Utf8.parse(ivStr);
//   }
//   let srcs = CryptoJS.enc.Utf8.parse(word);
//   var encrypted = CryptoJS.AES.encrypt(srcs, key, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.ZeroPadding
//   });
//   return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
//
// }
/**
 \* AES 解密 ：字符串 key iv 返回base64
 *
 \* @return {string}
 */
export function doDecryptData(word, keyStr, ivStr) {
  let key = KEY;
  let iv = IV;

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr);
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  }
   word = word.replace(/\s/g,'')
  let base64 = CryptoJS.enc.Base64.parse(word);
  let src = CryptoJS.enc.Base64.stringify(base64);

  let decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

  return JSON.parse(decryptedStr)
}
// export function doDecryptData(data) {
//   let str=data
//   let decryptData=sm2.doDecrypt(str, privateKey);
//   return   JSON.parse(decryptData)
// }
