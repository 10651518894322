export function showName(user){
	if(!user){
		return ''
	}
  if(user.username){
    return user.username
  }
  if(!user.phone){
    return '';
  }
	let mobile = user.phone+'';
	let start =mobile.substr(0,3);
	let end =mobile.substr(-4);
	return start+'****'+end;
}
