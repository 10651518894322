<template>
  <div>
    <topNav></topNav>
    <div class="top-banner">
      <img src="../assets/image/image12.png" alt="">
    </div>
    <div class="divBox">
      <div class="elrowBox">
        <el-row type="flex" justify="center">
          <el-col :lg="24" :xl="24" :md="22" :sm='23' :xs='23'>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>资讯列表</el-breadcrumb-item>
              <el-breadcrumb-item>政策信息</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="incontentRight  ">
              <ul class='incRightTitle flex'>
                <li class=" cursor-pointer" :class="titleOne==1?'activeLi':''" @click="chose(1)">新闻资讯</li>
                <li class="cursor-pointer"  :class="titleOne==2?'activeLi':''" @click="chose(2)">公司新闻</li>
                <li class="cursor-pointer"  :class="titleOne==3?'activeLi':''" @click="chose(3)">政策信息</li>
              </ul>
              <div class="borderB"></div>
              <div class="incRightCon ">
                 <ul class="incRightConUl">
                      <li class="flex cursor-pointer " v-for="item in newList" @click="gotoDetail">
                        <i class="elRadom"></i>
                          <p class="elOne content hoverFont">{{item.name}}</p>
                          <p class="time">{{item.time}}</p>
                      </li>
                  </ul>
                  <div class="flex justify_end padding_10">
                    <el-pagination  background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage4"
                         :page-sizes="[10,20,50,100, 200, 300, 400]"
                         :page-size="pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
                       </el-pagination>
                  </div>
              </div>



            </div>
          </el-col>

        </el-row>
      </div>
    </div>
    <footerBox></footerBox>
  </div>
</template>

<script>
  export default {
    name: 'home',
    components: {

    },
    data() {
      return {
      newList:[
      {name:'人力资源社会保障部办公厅关于进一步做好职称评审工作的通知',time:'2023-01-03'},
      {name:'收好这张图！享受个人养老金税收优惠政策三步走',time:'2023-01-03'},
      {name:'把优秀人才集聚到党和人民事业中来（新论）',time:'2023-01-03'},
      {name:'开始确认，可以退钱了',time:'2023-01-03'},
      {name:'收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走收好这张图！享受个人养老金税收优惠政策三步走',time:'2023-01-03'},
      {name:'可获6万元资助！2022年“武汉英才”计划培育支持专项科技创新领域申报启动',time:'2023-01-03'},],
       currentPage1: 5,
              currentPage2: 5,
              currentPage3: 5,
              currentPage4: 1,
              total:5,
              pageSize:10,
              titleOne:1
      }
    },
    methods:{
      gotoDetail(e){
        this.$router.push({path:'/detail',query:{id:11}})
      },
      chose(e){
        this.titleOne=e
      },
      handleSizeChange(val) {
              console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
              console.log(`当前页: ${val}`);
            }
    }
  }
</script>

<style lang="less" scoped>
.recruitBtn{
  margin-bottom: 38px ;
  li{
    width: 19%;
    padding-top: 9%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    img{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


      .incontentRight{
        margin-bottom: 48px;
        background: #FFFFFF;
        margin-top: 27px;
        .incRightTitle{
            height: 60px;
            padding: 0 8px;
            li{
              line-height: 48px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 48px;
              color: #333333;
              padding: 0 16px;
            }
            .activeLi{
              color: #AD9C72;

            }
        }

      }
      .incRightCon{
          padding: 16px;
      }
       .incRightCon .incRightConHot{
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #FDF7ED;
        border-radius: 10px;
        p{
          &:first-child{
            width: 52px;
            flex-shrink: 0;
            text-align: center;
            // padding: 0 10px;
              height: 29px;
              background: linear-gradient(90deg, #F6A14B -1.91%, #F27E0B 100%);
              border-radius: 4px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 29px;
              color: #FFFFFF;
              margin: 0 10px;
          }
          &:last-child{
            flex: 1;
            flex-shrink: 0;
            width: calc(100% - 60px);
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 29px;
              color: #333333;
          }
        }
      }
      .incRightConUl{
        min-height: 480px;
        li{

          line-height: 48px;
          height: 48px;
          i{
            font-size: 26px;
            color:#AD9C72;
          }
          div{
            overflow: hidden;
            // max-width: 80%;
          }
          .content{
             width: 85%;
            color: #333333;
          }
          p{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

          }
          .time{
            width:100px ;
            text-align: right;
             color: #999999;
          }
          .status{
            width: 72px;
            height: 24px;
            background: #FDF7ED;
            border-radius: 4px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #FF5202;
            text-align: center;
          }
        }

      }
</style>
