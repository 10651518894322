<template>
  <div class="footerBox">
    <div class="elrowBox">

    </div>
  </div>

</template>

<script>
  export default {
    name: 'home',
    components: {

    },
    data() {
      return {
        Sindex: 0,
        searchone: 1
      }
    },

    methods: {

    }
  }
</script>

<style lang="less" scoped>
  
  .footerBox{
    background-image: url(../assets/img-new/foot-bg.png);
    background-size: 100% 100%;
    width: 100%;
    height: 0;
    padding-top: 12%;
  }
</style>
