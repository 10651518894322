import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user:{},
        haveLogin:false,
        loginType:'person'
    },
    mutations: {
        GET_USER: (state, value) => {
            state.user = value
        },
        GET_LOGIN: (state, value) => {
            state.haveLogin = value
        },
        SET_LOGIN_TYPE:(state, value)=>{
            state.loginType = value
        }
    },
    actions: {

        geInfos({commit}, localeVal) {
            commit('GET_LOGIN', localeVal);
            // getCurrentStation({
            // 	code: localeVal
            // }).then(result => {
            // 	if (result.code == 200) {
            // 		uni.setStorageSync('Scode', result.data.code)
            // 		uni.setStorageSync('Sname', result.data.name)
            // 		uni.setStorageSync('Sid', result.data.id)
            // 		uni.setStorageSync('enableStatus', result.data.enableStatus)
            // 		commit('enableStatus', result.data.enableStatus)
            // 		commit('stationTitle', result.data.name)
            // 		commit('disinfect', result.data.disinfect)
            // 		// uni.$emit('prepare')
            // 	} else {
            // 		this.$refs.mToast.showToast('');
            // 	}
            // })
        },
    }
})
export default store
