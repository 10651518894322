<template>
  <div>
    <topNav></topNav>
    <div class="mine-banner">
      <img src="../assets/image/mineBanner.png" alt="">
    </div>
    <div class="divBox">
      <div class="elrowBox" style="margin-top: -123px;">
        <el-row type="flex" justify="center">
          <el-col :lg="24" :xl="24" :md="22" :sm='23' :xs='23'>
            <div class="flex justify_between align-start">
              <mineMenu></mineMenu>
              <div class="miContent flex-1 ">
                <div class="miCtitle">简历1
                  <p class=" mibackBtn miCbodyBtn" @click="$router.push('/apply')"> <i class="el-icon-back"></i> 返回</p>
                </div>
                <div class="miCbody">
                  <!-- 列表 -->
                  <div class="addresunmeBox">
                    <!-- 基本信息开始 -->
                    <div class="addresunmeTitle flex justify_between">
                      <p>基本信息</p>
                    </div>
                    <div class="margin-bottom-20">
                      <!-- 回显工作简历 -->
                      <div class="basicMsgInfo flex border-bot pd-b30 justify_between" >
                        <div>
                          <h6>李某某</h6>
                          <p class="info14 margin-tb10">
                            <span class="pd-tb4" style="padding-left: 0;">女</span>|<span class="pd-tb4">12岁</span>|<span
                              class="pd-tb4">应届</span>|<span class="pd-tb4">党员</span>
                          </p>
                          <div class="flex ">
                            <p class="info14 marginr-30"><span class="color6">手机号：</span></p>
                            <p class="info14 marginr-30"><span class="color6">邮箱：</span></p>
                            <p class="info14 marginr-30"><span class="color6">身份证号：</span></p>
                          </div>
                        </div>
                        <div style="margin-right: 10%;">
                          <div style="width: 98px;height: 126px;">
                            <img style="object-fit: cover;width: 100%;height: 100%;"  src="../assets/muban.jpg" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 基本信息结束 -->
                    <!-- 工作经历开始 -->
                    <div class="addresunmeTitle flex justify_between">
                      <p>工作经历</p>
                    </div>
                    <div class="margin-bottom-20">
                      <ul class="border-bot pd-b30" v-if="workList.length>0">
                        <li class="flex justify_between align-start padding-tb20 " :class="index==3?'':'border-botd'"
                          v-for="(item,index) in 4">
                          <div style="width: 80%;" class="flexS0">
                            <div class="flex margin-bottom-10">
                              <p class="info14 margin-tb10">
                                <span class="pd-tb4" style="padding-left: 0;">武汉XXXXXX有限责任公司</span>|<span
                                  class="pd-tb4">开发工程师</span>
                              </p>
                              <p class="info14 color6 marginl-30">2023.01~2023.01</p>
                            </div>
                            <div class="flex align-start">
                              <p class="info14 color6 flexS0 " style="width: 70px;">离职原因：</p>
                              <p class="info14 ">原职原因离职原因离职原因离职原因离职原因离职原因离职原因离职原因离职原因</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <!-- 添加工作经历 -->
                      <div class="msgEmpty" v-if="workList.length<=0">暂无内容</div>
                    </div>

                    <!-- 教育经历开始 -->
                    <div class="addresunmeTitle flex justify_between">
                      <p>教育经历</p>
                    </div>
                    <div class="margin-bottom-20">
                      <ul class="border-bot pd-b30" v-if="educationList.length>0">
                        <li class="flex justify_between  padding-tb20 " :class="index==3?'':'border-botd'"
                          v-for="(item,index) in 4">
                          <div style="width: 80%;" class="flexS0 flex ">
                            <p class="info14 ">
                              <span class="pd-tb4" style="padding-left: 0;">武汉工程大学</span>|<span
                                class="pd-tb4">软件工程</span>|<span class="pd-tb4">本科</span>
                            </p>
                            <p class="info14 color6 marginl-30">2023.01~2023.01</p>
                          </div>

                        </li>
                      </ul>
                      <div class="msgEmpty" v-if="educationList.length<=0&&!educationMsg">暂无内容</div>

                    </div>
                    <!-- 教育经历结束 -->

                    <!-- 证书开始 -->
                    <div class="addresunmeTitle flex justify_between">
                      <p>职(执)称与资格证件 <span style="font-size: 14px;color: #666;">（包含学历/学位证书）</span></p>

                    </div>
                    <div class="margin-bottom-20">
                      <ul class="border-bot pd-b30" v-if="certificateList.length>0">
                        <li class="flex justify_between  padding-tb20 " :class="index==3?'':'border-botd'"
                          v-for="(item,index) in 4">
                          <div style="width: 80%;" class="flexS0 flex ">
                            <p class="info14 ">国家一级工程师</p>
                            <p class="info14 color6 marginl-30">BH268685653802</p>
                            <p class="info14 color6 marginl-30">2023.01~2023.01</p>
                          </div>

                        </li>
                      </ul>
                      <div class="msgEmpty" v-if="certificateList.length<=0">暂无内容</div>

                    </div>
                    <!-- 证书结束 -->
                    <!-- 证书开始 -->
                    <div class="addresunmeTitle flex justify_between">
                      <p>附件简历 <span style="font-size: 14px;color: #666;">（包含学历/学位证书）</span></p>
                    </div>
                    <div>
                      <FileUpload :uploadShow='false' v-model="FileList" v-if="FileList.length>0"></FileUpload>
                      <empty width='150px'  v-if="FileList.length<=0"></empty>
                    </div>
                    <div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <footerBox></footerBox>
  </div>
</template>

<script>
  export default {
    name: 'home',
    components: {

    },
    data() {
      return {
        resumeForm: {
          name: '',
          card: '',
          sex: 1,
          zz: '',
          hy: '',
          mobile: '',
          yj: '2'
        },
        rules: {
          name: [{
            required: true,
            message: '请输入简历名称',
            trigger: 'blur'
          }, ],

        },
        educationForm: {
          name: '',
          card: '',
          sex: 1,
          zz: '',
          hy: '',
          mobile: '',
          yj: '2',
          date: ''
        },
        workForm: {
          name: '',
          card: '',
          sex: 1,
          zz: '',
          hy: '',
          mobile: '',
          yj: '2',
          date: ''
        },
        certificateForm: {
          name: '',
          card: '',
          sex: 1,
          zz: '',
          hy: '',
          mobile: '',
          yj: '2',
          date: ''
        },
        options: [{
          value: '1',
          label: '是'
        }, {
          value: '2',
          label: '否'
        }],
        optionseducation: [{
          value: '1',
          label: '高中'
        }, {
          value: '2',
          label: '本科'
        }],
        checked: false,
        show: false,
        basicMsg: false,
        educationMsg: false,
        workMsg: false,
        certificateMsg: false,
        certificateList: [{}],
        educationList: [{}],
        workList: [{}],
        FileList:["/简历1.pdf"]

      }
    },
    methods: {
      certificateBtn() {
        this.certificateMsg = true
      },
      workBtn() {
        this.workMsg = true
      },
      educationBtn() {
        this.educationMsg = true
      },
      basicMsgBtn() {
        this.basicMsg = true
      },
      addresumeCommit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.show = false;
            this.handleClose()
          } else {
            console.log('error submit!!');
            return false;
          }
        });

      },

    }
  }
</script>

<style lang="less" scoped>
  .addresunmeBox {
    padding: 14px 0;
  }

  .addresunmeTitle {
    margin-bottom: 18px;

    p {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      color: #333333;
      position: relative;

      &::before {
        content: '';
        width: 22px;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0px;
        background: #C8B484;
      }
    }

    /deep/ .el-button--text {
      color: #C8B484;
    }
  }

  .reformBox {
    padding: 20px 10px;
    background: #F7F8F9;
    color: #333333;

  }



  .msgEmpty {
    box-sizing: border-box;
    width: 100%;
    height: 98px;
    background: #F7F8F9;
    border: 1px dashed #C4CCD1;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 98px;
    text-align: center;
    color: #333333;
  }


  .basicMsgInfo {

    h6 {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 31px;
      color: #333333;
    }
  }

  /deep/ .el-upload--picture-card:hover {
    border-color: #C8B484 !important;
    color: #C8B484 !important;
  }



  .recruitBtn {
    margin-bottom: 38px;

    li {
      width: 19%;
      padding-top: 9%;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }


  .incontentRight {
    margin-bottom: 48px;
    background: #FFFFFF;
    margin-top: 27px;

    .incRightTitle {
      height: 48px;
      padding: 0 8px;

      li {
        line-height: 48px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 48px;
        color: #333333;
        padding: 0 16px;
      }

      .activeLi {
        color: #AD9C72;

      }
    }

  }

  .incRightCon {
    padding: 16px;
  }

  .incRightCon .incRightConHot {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #FDF7ED;
    border-radius: 10px;

    p {
      &:first-child {
        width: 52px;
        flex-shrink: 0;
        text-align: center;
        // padding: 0 10px;
        height: 29px;
        background: linear-gradient(90deg, #F6A14B -1.91%, #F27E0B 100%);
        border-radius: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #FFFFFF;
        margin: 0 10px;
      }

      &:last-child {
        flex: 1;
        flex-shrink: 0;
        width: calc(100% - 60px);
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: #333333;
      }
    }
  }

  .incRightConUl {
    min-height: 480px;

    li {

      line-height: 48px;
      height: 48px;

      i {
        font-size: 26px;
        color: #AD9C72;
      }

      div {
        overflow: hidden;
        // max-width: 80%;
      }

      .content {
        width: 85%;
        color: #333333;
      }

      p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

      }

      .time {
        width: 100px;
        text-align: right;
        color: #999999;
      }

      .status {
        width: 72px;
        height: 24px;
        background: #FDF7ED;
        border-radius: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FF5202;
        text-align: center;
      }
    }

  }

  .miContent {
    background: #FFFFFF;
  }

  .miCtitle {
    padding: 20px 37px;
    position: relative;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    border-bottom: 1px solid #DFDFDF;
    position: relative;

    &::before {
      content: '';
      width: 4px;
      height: 20px;
      position: absolute;
      top: 20px;
      left: 25px;
      background: #C8B484;
    }
  }

  .miCbody {
    padding: 0px 20px 0;
    min-height: 529px;
  }

  .miCbodyBtn {
    cursor: pointer;
    padding: 0 10px;
    height: 32px;
    background: #FDF7ED;
    border-radius: 2px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #AD9C72;
    text-align: center;
    position: absolute;
    top: 16px;
    right: 20px;
  }

  .mibackBtn {
    border: 1px solid #e4e7ed;
    background-color: #fff;
    color: #666;
    letter-spacing: 1px;
  }

  .unread {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    span {
      color: #FF0000;
    }
  }

  .lookStatus {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    /deep/ .el-input__inner {
      border: 0 !important;
      background: #F7F8F9;
      height: 32px;
      line-height: 32px;
    }

    /deep/.el-select {
      width: 100px !important;
      margin: 0 10px !important;
    }

    /deep/ .el-input__icon {
      line-height: 32px;
    }
  }

  .msgTableBox {
    min-height: 400px;
    padding: 0px 0;

    /deep/ .el-table__header-wrapper th {
      background: #F7F8F9;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      border: 0 !important;
    }

    /deep/ tr:last-child td {
      border: 0 !important;
      cursor: pointer;
    }

    /deep/ .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      display: none !important;
    }

    /deep/ .el-table .el-table__cell {
      padding: 20px 0;
    }

    // /deep/  .el-table td.el-table__cell div{
    //   color: #333;
    // }
    /deep/ .el-button--text {
      color: #666666;
    }

  }

  .resumName {
    p {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      margin-right: 20px;
    }

    span {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }

  .resuMore {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;

    span {
      padding: 4px;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .emptyDiv {
    padding: 50px 0 0;

    p {
      text-align: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;

      span {
        color: #AD9C72;
        cursor: pointer;
      }
    }
  }
</style>
