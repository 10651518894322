import {arrayChangeKey } from './index.js'
import {getAllCategory} from '@/api/login.js'
export default {
  getAllCate(callback){
    getAllCategory().then(res=>{
      if(res.data){
        let cateMap = arrayChangeKey(res.data ,'id');
        for(let id in cateMap){
          let cate = cateMap[id]
          let parentTitle =[];

          if(cate.parentPath){
            let parentIds = (cate.parentPath+'').split(',')
            // parentIds.reverse();
            parentIds.forEach(_id=>{
              if(cateMap[_id]){
                parentTitle.push(cateMap[_id].title)
              }
            })

          }else{
             parentTitle.push(cate.title)
          }

          cate.parentTitle =parentTitle;
        }
        callback(cateMap);
      }
    })
  },
}
